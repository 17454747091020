import React, { useState } from "react";
import ImageGallery from "react-image-gallery";
import { CLOUD_FRONT_URL } from "../config";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import image360spin from "../assets/images/spin.png"
import { SpinViewer } from "./modalViewer/SpinnerViewer";
const ProductGallary = ({ imageDetailObject, images }) => {
  const [is360Selected, setIs360Selected] = useState(true);

  let imageData = images?.map((val) => {
    return { original: `${CLOUD_FRONT_URL}${val?.image}`, thumbnail: `${CLOUD_FRONT_URL}${val?.thumb}` }
  })

  let image360Data = imageDetailObject?.spin360Renders.map((val) => {
    return { original: `${val?.detailView}`, thumbnail: `${image360spin}` }
  })

  if (image360Data.length) {
    imageData = image360Data?.concat(imageData)
  }


  const [page, setPage] = useState(1);
  const [imagesToShow, setImagesToShow] = useState(imageData.slice(0, 3) || [])

  const renderLeftNav = (onClick) => {
    const handlePrev = () => {
      if (page >= 1) {
        setPage(page - 1)
        const sliceImage = imageData.slice((page - 2) * 3, ((page - 2) * 3) + 3)
        setImagesToShow(sliceImage)
        onClick();
      }
    };

    return (
      <button
        className="image-gallery-left-nav"
        onClick={handlePrev}
        style={{
          padding: "15px",
          left: imagesToShow?.length === 3 ? "500px" : imagesToShow?.length === 2 ? "33%" : "500px"
        }}
        hidden={page === 1}
      >
        <span className="image-gallery-icon image-gallery-left-icon">
          <BsChevronLeft />
        </span>
      </button>
    );
  };

  const renderRightNav = (onClick) => {
    const handleNext = () => {
      if (imageData?.length > page * 3) {
        setPage(page + 1)
        const sliceImage = imageData.slice(page * 3, (page * 3) + 3)
        setImagesToShow(sliceImage)
        onClick();
      }
    };

    return (
      <button
        className="image-gallery-right-nav"
        style={{
          padding: "15px",
          right: imagesToShow?.length === 3 ? "500px" : imagesToShow?.length === 2 ? "33%" : "500px"
        }}
        onClick={handleNext}
        hidden={page === Math.ceil(imageData?.length / 4)}
      >
        <span className="image-gallery-icon image-gallery-right-icon">
          <BsChevronRight />
        </span>
      </button>
    );
  };

  const renderImagesToShow = (item) => {
    return (
      <div className="my-custom-image-container d-flex justify-content-center">
        {item.original.includes("spin-render")
          ?
          <SpinViewer loadS3bucket360={item.original} />
          :
          <img className="image-gallery-image" src={item.original} alt={item.originalAlt} height="" width="" sizes="" title="" loading="eager" />}
      </div>
    );
  };

  const check360Image = (index) => {
    (imagesToShow[index].original.includes("spin-render")) ? setIs360Selected(true) : setIs360Selected(false)
  };

  return (
    <>
      {
        imageData.length === 1 ?
          <div className="single-product-display">
            <img className="" src={imageData[0]?.original} alt={imageData[0]?.originalAlt} />
          </div>
          :
          <ImageGallery
            items={imagesToShow}
            lazyLoad={true}
            showPlayButton={false}
            showFullscreenButton={true}
            renderLeftNav={renderLeftNav}
            renderRightNav={renderRightNav}
            renderItem={renderImagesToShow}
            disableSwipe={is360Selected}
            onSlide={check360Image}
          />
      }
    </>
  );

};

export default ProductGallary;
