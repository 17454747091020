import React from 'react';
import { CLOUD_FRONT_URL } from '../../config';

export const SpinViewer = ({ loadS3bucket360 }) => {
  console.log("🚀 ~ file: SpinViewer.js:104 ~ SpinViewer ~ loadS3bucket360:", loadS3bucket360)

  let folderURL = ""
  let fileName = ""
  if (loadS3bucket360) {
    const lastSlashIndex = loadS3bucket360?.lastIndexOf('/');

    if (lastSlashIndex !== -1) {
      // Use substring to remove the last part
      folderURL = loadS3bucket360?.substring(0, lastSlashIndex);
    } else {
      console.log("Invalid URL format.", loadS3bucket360);
    }
    fileName = loadS3bucket360?.match(/\/([0-9a-fA-F]+)_/)?.[1] || ''
  }


  const loadSirvJS = () => {
    return new Promise(resolve => {
      const script = document.createElement("script");
      script.src = "https://app.poly9.ai/public/360-assets/p9-360-view.min.js";
      script.type = "text/javascript";
      script.async = true;
      script.onload = resolve;
      document.body.appendChild(script);
    });
  }

  loadSirvJS()

  return <div style={{ width: "55%" }}>
    <div className="p9-360 w-100 "
      data-folder={`${CLOUD_FRONT_URL}${folderURL}/`}
      data-filename-x={fileName ? `${fileName}_{index}.jpg` : '{index}.jpg'}
      data-amount-x={fileName ? '32' : "48"}
      data-pointer-zoom="2.5"
      data-autoplay data-play-once
    >
    </div>
  </div>
  // return <div
  //   key={spin360}
  //   className={`Sirv ${className}`}
  //   data-options="fullscreen.enable:false;"
  // >
  //   <div class="Sirv" data-src={`${spin360}?fullscreen=false`}></div>
  // </div>
}