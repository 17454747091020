import { gql } from "@apollo/client";

const emailObject = `
    {
      _id
      email
      message
      sentAt
      shortId
      active
      expirationDate
    }
  `;

const folderAttachmentObject = `{
    file
    name
    type
    notes
    folderId
    createdAt
}`

const AngleImagePayload = `
    {
      _id
      name
      image
      thumb
      detailView
    }
  `;

const SpecificationsObject = `
    {
      unit
      length
      width
      height
    }
  `;

const packageDimensionObject = `
    {
      unit
      length
      width
      height
    }
  `;

const Spin360RendersPayload = `
    {
      _id
      thumb
      detailView
      renderId
      spin360
    }
  `;

const partObject = `
    {
      _id
      partId: _id
      parts
      materialCategory
      materialCategoryName
      materialName
      name
      isCustomize
      materialId
      pantone
      hexCode
      colorType
      finishId
      finishName
      diffuse
      metallicRoughness
      finishThumb
      normal
      metalness
      ao
      aoValue
      roughness
      transparency
      patternId
      patternName
      patternDiffuse
      customiseFinishIds
      transparencyValue
      subFinishDiffuse
      isAlphaPattern
      alphaTest
      alphaDiffuse
      shapeId
      shapes {
        _id
        name
        objUrl
        gltfUrl
        fbxUrl
        thumb
        groupId
      }
      limitedFinishes
      glossiness
      showInShare
      displacement
      displacementScale
      displacementBias
      repeatX
      repeatY
      offsetX
      offsetY
      rotate
    }
  `;

const RenderedImagePayload = `
    {
      _id
      thumb
      detailView
      renderId
    }
  `;

const productStyleDetail = `
    _id
    name
    objUrl
    fbxUrl
    gltfUrl
    usdzUrl
    styledGltfUrl
    status
    category
    description
    isExclusive
    selectedCompany
    detailView
    thumb
    companyId
    uniqueCode
    sku
    createdAt
    createdByUserName
    reviewedBy
    reviewedByUserName
    reviewedAt
	  modifiedByUserName
    productStyleId
    modifiedAt
    parts ${partObject}
    emails {
      _id
      email
      message
      sentAt
      shortId
      active
      expirationDate
    }
    shareId
    tags
    companyName
    companyLogo
    cbm
    size
    supplierId
    renderedImages ${RenderedImagePayload}
    spin360Renders ${Spin360RendersPayload}
    partsMaterial {
      part
      material
    }
    sketchFabId
    noStyle
    specification {
      height
      width
      length
    }
    isDemoProduct
    isAccessoriesProduct
    price
    polyFoundryCategory
    customizedSettings
    accessoriesTags
    priceLabel
    priceValue
    envMap
    brightnessValue
    zoomLock
    selectedProductAttribute
    styleLights {
      _id
      name
      position {
        _id
        x
        y
        z
      }
      visible
      type
      intensity
      distance
      decay
      angle
      penumbra
    }
    polyFoundryVisible
    featuredProduct
    guestProduct
    storeCategories {
      _id
      title
      slug
      type
      icon
    }
    shareViewer
    threeDProductEditViewer
    storeSubCategories {
      _id
      parentCategoryId
      title
      slug
      type
      icon
    }
    storeMaterials {
      _id
      title
      slug
    }
    productListEditStatus
    productSegmentStatus
    productListNote
    productListNoteImg
    productListNoteImgThumb
    modelPosition {
      _id
      x
      y
      z
    }
    minPolarAngle
    maxPolarAngle
    foldersMeta {
      _id
      name
      folderId
    }
    modelScreenshotPosition {
      _id
      x
      y
      z
    }
    modelViewerSetting {
      exposure
      shadowIntensity	
      legacyEnvironmentImage
      environmentImage
      cameraOrbit
      disableZoom
      cameraTarget
      disableTap
      transforms {
        roll
        pitch
        yaw
        scaleX
        scaleY
        scaleZ
      }
    }
  `;
const productStyleDetailForFolder = `
    _id
    name
    objUrl
    fbxUrl
    gltfUrl
    usdzUrl
    styledGltfUrl
    status
    category
    description
    isExclusive
    selectedCompany
    detailView
    thumb
    companyId
    uniqueCode
    sku
    createdAt
    createdByUserName
    reviewedBy
    reviewedByUserName
    reviewedAt
	  modifiedByUserName
    productStyleId
    modifiedAt
    parts ${partObject}
    emails {
      _id
      email
      message
      sentAt
      shortId
      active
      expirationDate
    }
    FolderAttachment ${folderAttachmentObject}

    shareId
    tags
    companyName
    companyLogo
    cbm
    size
    supplierId
    renderedImages ${RenderedImagePayload}
    spin360Renders ${Spin360RendersPayload}
    partsMaterial {
      part
      material
    }
    sketchFabId
    noStyle
    specification {
      height
      width
      length
    }
    isDemoProduct
    isAccessoriesProduct
    price
    polyFoundryCategory
    customizedSettings
    accessoriesTags
    priceLabel
    priceValue
    envMap
    brightnessValue
    zoomLock
    selectedProductAttribute
    styleLights {
      _id
      name
      position {
        _id
        x
        y
        z
      }
      visible
      type
      intensity
      distance
      decay
      angle
      penumbra
    }
    polyFoundryVisible
    featuredProduct
    guestProduct
    storeCategories {
      _id
      title
      slug
      type
      icon
    }
    shareViewer
    threeDProductEditViewer
    storeSubCategories {
      _id
      parentCategoryId
      title
      slug
      type
      icon
    }
    storeMaterials {
      _id
      title
      slug
    }
    productListEditStatus
    productSegmentStatus
    productListNote
    productListNoteImg
    productListNoteImgThumb
    modelPosition {
      _id
      x
      y
      z
    }
    minPolarAngle
    maxPolarAngle
    foldersMeta {
      _id
      name
      folderId
    }
    modelScreenshotPosition {
      _id
      x
      y
      z
    }
    modelViewerSetting {
      exposure
      shadowIntensity	
      legacyEnvironmentImage
      environmentImage
      cameraOrbit
      disableZoom
      cameraTarget
      disableTap
      transforms {
        roll
        pitch
        yaw
        scaleX
        scaleY
        scaleZ
      }
    }
  `;

const ImagePayload = `
    {
      _id
      key
      name
      uniqueCode
      sku
      description
      productStyleId
      tags
      image
      thumb
      detailView
      companyId
      companyName
      companyLogo
      shareId
      createdAt
      modifiedAt
      createdByUserName
      modifiedByUserName
      emails ${emailObject}
      FolderAttachment ${folderAttachmentObject}
      angleImages ${AngleImagePayload}
      specification ${SpecificationsObject}
      packageDimension ${packageDimensionObject}
      cbm
      size
      materials 
      finishes 
      isDemoProduct
      priceLabel
      priceValue
      spin360
      polyFoundryVisible
      featuredProduct
      guestProduct
      storeCategories{
        _id
        title
        slug
        type
        icon
      }
      storeSubCategories{
        _id
        parentCategoryId
        title
        slug
        type
        icon
      }
      storeMaterials {
        _id
        title
        slug
      }
      materialCategories {
        _id
        materialId
        materialName
      }
      ip
      mp
      ipMpUnit
      ipDimension ${packageDimensionObject}
      ipCBM
      mpDimension ${packageDimensionObject}
      mpCBM
      foldersMeta {
        _id
        name
        folderId
      }
      spin360Renders ${Spin360RendersPayload}
      productCategories
      selectedProductAttribute
    }
  `;
const ImagePayloadForMultipleImages = `
    {
      _id
      key
      name
      uniqueCode
      sku
      description
      productStyleId
      tags
      image
      thumb
      detailView
      companyId
      companyName
      companyLogo
      shareId
      createdAt
      modifiedAt
      createdByUserName
      modifiedByUserName
      emails ${emailObject}
      angleImages ${AngleImagePayload}
      specification ${SpecificationsObject}
      packageDimension ${packageDimensionObject}
      cbm
      size
      materials 
      finishes 
      isDemoProduct
      priceLabel
      priceValue
      spin360
      polyFoundryVisible
      featuredProduct
      guestProduct
      storeCategories{
        _id
        title
        slug
        type
        icon
      }
      storeSubCategories{
        _id
        parentCategoryId
        title
        slug
        type
        icon
      }
      storeMaterials {
        _id
        title
        slug
      }
      materialCategories {
        _id
        materialId
        materialName
      }
      ip
      mp
      ipMpUnit
      ipDimension ${packageDimensionObject}
      ipCBM
      mpDimension ${packageDimensionObject}
      mpCBM
      foldersMeta {
        _id
        name
        folderId
      }
      spin360Renders ${Spin360RendersPayload}
      productCategories
      selectedProductAttribute
    }
  `;

const FolderPayload = `
  {
    _id
    name
    emails {
      _id
      email
      message
      sentAt
      shortId
      active
      expirationDate
    }
  }
`;

export const GET_IMAGES_STYLES_WITH_PAGINATION_COUNT = gql`
query get(
  $name: [String], 
  $offset: Int, 
  $limit: Int!, 
  $sort: String, 
  $folders: [String],
  $companyId:String
) {
  getImagesStylesWithPagination(
    data: { 
      name: $name, 
      offset: $offset, 
      limit: $limit, 
      sort: $sort, 
      folders: $folders,
      companyId: $companyId 
    }
  )  ${ImagePayloadForMultipleImages}
  getImagesStylesCountForPagination(
    data: { 
      name: $name, 
      folders: $folders 
    }
  ) {
    count
    message
  }
}
`;
export const GET_FOLDER_DETAIL_BY_ID = gql`
query get($_id: String! ,$companyId: String) {
  getFolderDetailById( _id: $_id , companyId : $companyId ) ${FolderPayload}
}
`;

export const GET_COMPANY_DETAIL_BY_COMPANY_ID = gql`
query($companyId: String){
  getCompanyDetailByCompanyId(companyId: $companyId ) {
    _id
    name
    profileUrl
    visibility
    about
    type
    address {
      lineOne
      lineTwo
      cityId
      countryId
      stateId
      zipCode
    }
    referenceBy
    logo
    createdAt
    status
    customDomain
    phone
    website
    sharableLinkWebsite
    email
    getCountry {
      _id
      name
    }
    getState {
      _id
      name
    }
    getCity {
      _id
      name
    }
    showroom
    accessRestrictedCountry
    lifestyleRender
    lifestyleGeneralRooms
    lifestyleExclusiveRooms
    virtualShowroom
    shapeReplace
    shadowEffect
    ipWhiteList
    polyFoundryAccess
    viewerWebPSupport
    shareViewer
    threeDProductEditViewer
    ecommerceKey
    eCatalog
    virtualSpace
  }
}
`;
export const GET_PRODUCTS_STYLES_WITH_PAGINATION_WITH_COUNT = gql`
query pagination(
  $name: [String]
  $category: String
  $materialCategory: [String]
  $status: String
  $offset: Int
  $limit: Int!
  $sort: String
  $companyId: String
  $imageTags: [String]
  $isPublic: Boolean
  $recommendation: Boolean
  $demoProduct: Boolean
  $withNoStyle: Boolean
  $isDemoProduct: Boolean
  $isAccessoriesProduct: Boolean
  $productType: [String]
  $createdBy: [String]
  $reviewedBy: [String]
  $productListEditStatus: String
  $productSegmentStatus: String
  $productCategories: [String]
  $storeCategory: [String]
  $storeSubCategory: [String]
  $folders: [String]
) {
  getProductsStylesWithPagination(
    data: {
      name: $name
      category: $category
      materialCategory: $materialCategory
      status: $status
      offset: $offset
      limit: $limit
      sort: $sort
      companyId: $companyId
      imageTags: $imageTags
      recommendation: $recommendation
      demoProduct: $demoProduct
      withNoStyle: $withNoStyle
      isDemoProduct: $isDemoProduct
      isAccessoriesProduct: $isAccessoriesProduct
      productType: $productType
      createdBy: $createdBy
      reviewedBy: $reviewedBy
      productListEditStatus: $productListEditStatus
      productSegmentStatus: $productSegmentStatus
      productCategories: $productCategories
      storeCategory: $storeCategory
      storeSubCategory: $storeSubCategory
      folders: $folders
    }
  ) {
    ${productStyleDetail}
  }
  getProductsStylesCountForPagination(
    data: {
      name: $name
      category: $category
      materialCategory: $materialCategory
      status: $status
      sort: $sort
      imageTags: $imageTags
      isPublic: $isPublic
      recommendation: $recommendation
      demoProduct: $demoProduct
      withNoStyle: $withNoStyle
      isDemoProduct: $isDemoProduct
      isAccessoriesProduct: $isAccessoriesProduct
      productType: $productType
      createdBy: $createdBy
      reviewedBy: $reviewedBy
      productListEditStatus: $productListEditStatus
      productSegmentStatus: $productSegmentStatus
      productCategories: $productCategories
      storeCategory: $storeCategory
      storeSubCategory: $storeSubCategory
      folders: $folders
    }
  ) {
    count
    message
  }
}
`;

export const GET_IMAGE_STYLE_DETAIL_FOR_SELECTED_IMAGE_STYLE = gql`
query get($_id: String!, $companyId: String) {
  getImageStyleDetailForSelectedImageStyle(data: { _id: $_id companyId:$companyId}) ${ImagePayload}
}
`;
export const GET_PRODUCT_STYLE_DETAIL_BY_ID = gql`
query get($_id: String!, $companyId: String, $ref : String ) {
  getProductStyleDetailById(_id: $_id, companyId : $companyId, ref: $ref) {
    ${productStyleDetailForFolder}
  }
}
`;

export const UPDATE_IMAGE = gql`
mutation update(
  $_id: String!, 
  $name: String!,
  $companyId: String!,
  $sku: String
  $image: String!,
  $thumb: String!,
  $detailView: String!,
  $specification:SpecificationsObject,
  $packageDimension:packageDimensionObject,
  $materials:String,
  $finishes:String,
  $cbm:String,
  $size: String, 
  $description: String, 
  $tags: [String], 
  $isDemoProduct: Boolean
  $priceLabel: String
  $priceValue: Float
  $spin360: String
  $polyFoundryVisible: Boolean
  $featuredProduct: Boolean
  $guestProduct: Boolean
  $storeCategories:[StoreCategoriesObject]
  $storeSubCategories:[StoreCategoriesObject]
  $storeMaterials:[StoreCategoriesObject]
  $materialCategories:[MaterialCategoryObject]
  $FolderAttachment: folderAttachmentObject
  $ip: String
  $mp: String
  $ipMpUnit: String
  $ipDimension: packageDimensionObject
  $mpDimension: packageDimensionObject
  $ipCBM: String
  $mpCBM: String
  $productCategories: String
  $selectedProductAttribute: JSON
  $selectedProductAttributeWithoutId: [JSON]
) {
  updateImage(
    _id: $_id, 
    data: { 
      name: $name, 
      companyId: $companyId, 
      sku: $sku, 
      image: $image,
      thumb: $thumb,
      detailView: $detailView,
      specification:$specification,
      packageDimension:$packageDimension,
      materials:$materials,
      finishes:$finishes,
      cbm: $cbm, 
      size: $size,
      description: $description, 
      tags: $tags, 
      isDemoProduct: $isDemoProduct 
      priceLabel: $priceLabel 
      priceValue: $priceValue 
      spin360: $spin360 
      polyFoundryVisible: $polyFoundryVisible
      featuredProduct: $featuredProduct
      guestProduct: $guestProduct
      storeCategories: $storeCategories
      storeSubCategories: $storeSubCategories
      storeMaterials: $storeMaterials
      materialCategories: $materialCategories
      FolderAttachment: $FolderAttachment
      ip: $ip
      mp: $mp
      ipMpUnit: $ipMpUnit
      ipDimension: $ipDimension
      mpDimension: $mpDimension
      ipCBM: $ipCBM
      mpCBM: $mpCBM
      productCategories: $productCategories
      selectedProductAttribute: $selectedProductAttribute
      selectedProductAttributeWithoutId: $selectedProductAttributeWithoutId
    }
  ) ${ImagePayload}
}
`;

export const UPDATE_PRODUCT_OBJ = gql`
    mutation create(
      $id: String!
      $companyId: String
      $FolderAttachment: folderAttachmentObject
    ) {
      updateProductObj(
        id: $id
        companyId:$companyId
        FolderAttachment: $FolderAttachment
      ) {
        ${productStyleDetailForFolder}
      }
    }
`;

export const GET_SHARE_FOLDER_ITEMS = gql`
    query get(
      $name: [String] 
      $companyId: String
      $folderId: String
      $_id: ID
      $offset: Int
      $limit: Int
      $sort: String
    ) {
      getShareFolderData(
        data: { 
          _id: $_id
          name: $name 
          companyId: $companyId 
          folderId: $folderId 
          offset: $offset
          limit: $limit
          sort: $sort
        }
      )
      # getFolderProductWithPagination(
      #   data: {
      #     _id: $_id
      #     name: $name 
      #     companyId: $companyId 
      #     offset: $offset
      #     limit: $limit
      #     sort: $sort
      #   }
      # ) {
      #   count
      # }
    }
  `;