import "./App.css";
import RoutesLink from "./components/Routes";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Analytics, Auth } from "aws-amplify";
import { awsExports } from "./aws_exports";
import { USER_EMAIL, USER_PASSWORD } from "./config";

function App() {

  Auth.configure(awsExports);
  Analytics.configure({ disabled: true });
  Auth.signIn(USER_EMAIL, USER_PASSWORD).then(data => console.log("Dummy user Logged In")).catch(err => console.log("=========>Error", err)) // NEED TO LOGIN FOR AWS SECURITY

  return (
    <>
      <RoutesLink />
      <ToastContainer />
    </>
  );
}

export default App;
