import React, { useEffect } from 'react'
import { CLOUD_FRONT_URL } from '../../config'

const GoogleModalViewer = ({ itemProduct }) => {

  let modelViewer = document.querySelector("#modelViewer");
  useEffect(() => {
    if (modelViewer) {
      if (!itemProduct?.modelViewerSetting?.disableZoom) {
        modelViewer.setAttribute("disable-zoom", true);
      } else {
        modelViewer.removeAttribute("disable-zoom");
      }
      if (!itemProduct?.modelViewerSetting?.disableTab) {
        modelViewer.setAttribute("disable-tab", true);
      } else {
        modelViewer.removeAttribute("disable-tab");
      }
    }
    // eslint-disable-next-line
  }, [modelViewer])

  return (
    <model-viewer
      src={`${CLOUD_FRONT_URL}${itemProduct?.styledGltfUrl}`}
      poster={`${CLOUD_FRONT_URL}${itemProduct?.thumb}`}
      alt="A 3D model"
      data-aos="fade-up"
      data-aos-delay="100"
      style={{
        position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '75%', height: '70vh', margin: 'auto'
      }}
      id="modelViewer"
      auto-rotate=""
      camera-controls
      data-js-focus-visible=""
      ar
      ar-modes="webxr scene-viewer quick-look"
      interaction-prompt-threshold="0"
      exposure={itemProduct?.modelViewerSetting?.exposure}
      shadow-intensity={itemProduct?.modelViewerSetting?.shadowIntensity}
      camera-orbit={itemProduct?.modelViewerSetting?.cameraOrbit}
      camera-target={itemProduct?.modelViewerSetting?.cameraTarget}
      orientation={`${itemProduct?.modelViewerSetting?.roll || 0}deg ${itemProduct?.modelViewerSetting?.pitch || 0
        }deg ${itemProduct?.modelViewerSetting?.yaw || 0}deg`}
      scale={`${itemProduct?.modelViewerSetting?.scaleX || 1} ${itemProduct?.scaleY || 1} ${itemProduct?.scaleZ || 1
        }`}
      ios-src={
        itemProduct?.style?.usdzUrl
          ? `${CLOUD_FRONT_URL}${itemProduct?.style?.usdzUrl}`
          : null
      }
    ></model-viewer>
  )
}

export default GoogleModalViewer