import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import FolderProduct from './FolderProduct'
import CartModal from './CartModal';
import { toast } from 'react-toastify';

const Folder3DProductViewModal = ({ visible, closeModal, itemProduct, nextHandler, companyMeta, companyDetails, handleNotesChangeInput, notes, setNotes, cartProduct, setSelectedProduct, addToCartItem, removeProductFromCart, editNotes, submitEnquiry, enquiryLoader }) => {

  const [isOpenCartModal11, setIsOpenCartModal11] = useState(false);
  const selectItem = () => {
    setNotes("")
    const checkValidate = cartProduct.filter(data => data._id === itemProduct?._id)
    if (checkValidate?.length > 0) {
      toast.warning("Item already added.");
      return
    }
    setSelectedProduct(itemProduct)
    addToCartItem(itemProduct)
    toggleCartModal()
  }

  const toggleCartModal = () => {
    setIsOpenCartModal11(!isOpenCartModal11)
  }

  return (
    <Modal
      id="folder3DProductViewModal"
      show={visible}
      size="full"
      toggle={closeModal}
      className='image-details-modal'
    >
      {visible && itemProduct ? (
        <Modal.Body className="p-0 hvh-100">
          <FolderProduct toggle={closeModal} itemProduct={itemProduct} nextHandler={nextHandler} companyMeta={companyMeta} companyDetails={companyDetails} selectItem={selectItem} handleNotesChangeInput={handleNotesChangeInput} notes={notes} setNotes={setNotes} />

          <CartModal
            toggleCartModal={toggleCartModal}
            isOpenCartModal={isOpenCartModal11}
            removeProductFromCart={removeProductFromCart}
            cartProduct={cartProduct}
            editNotes={editNotes}
            submitEnquiry={submitEnquiry}
            enquiryLoader={enquiryLoader}
          />
        </Modal.Body>
      ) : null}
    </Modal>
  )
}

export default Folder3DProductViewModal