import React from "react";
// import { ReactComponent as Share } from "../assets/images/icons/share.svg";
//import p1 from "../assets/images/products/p1.png";
import ProductGallary from "./GallarySlider";

const ProductImages = ({imageDetailObject,  images }) => {
  return (
    <div className="product-images " style={{width:'100%'}}>
      <div className="product-images-wrap "style={{width:'100%'}}>
        {imageDetailObject &&<ProductGallary imageDetailObject={imageDetailObject} images={images} /> }
      </div>
    </div>
  );
};

export default ProductImages;
