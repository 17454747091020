import React from "react";

import { ReactComponent as Share } from "../assets/images/icons/share.svg";
import { ReactComponent as Expand } from "../assets/images/icons/expand.svg";
// import ProductGallary from "./GallarySlider";

const ProductGalleryImages = () => {
  return (
    <div className="product-images">
      <div className="product-images-wrap">
        {/* <ProductGallary /> */}
      </div>
      <div className="product-images-button">
        <button className="product-images-button-icon">
          <Share />
        </button>
        <button className="product-images-button-icon">
          <Expand />
        </button>
      </div>
    </div>
  );
};

export default ProductGalleryImages;
