import { useMutation, useQuery } from '@apollo/client'
import { GET_COMPANY_DETAIL_BY_COMPANY_ID, GET_SHARE_FOLDER_ITEMS } from "./shareFolderQuery/query"
import React, { useCallback, useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Collapse, Row, Card, Modal, Form, FloatingLabel } from 'react-bootstrap';
import { CLOUD_FRONT_URL } from '../config';
import TagsInput from 'react-tagsinput';
import "../assets/css/pages/folder.scss"
import Folder2DProductViewModal from './folder2DProductViewModal';
import { GET_COMPANY_META, REGISTER_SHARE_VIEW, SEND_INQUIRY_FOLDER_CARTS_PRODUCT } from './query';
import { Input } from 'reactstrap';
import CartModal from './CartModal';
import { ReactComponent as Close } from "../assets/images/icons/close.svg";
import thankYouImg from "../assets/images/thankyoumodal.png";
import defaultImg from "../assets/images/default.png";
import _ from 'lodash'
import Folder3DProductViewModal from './folder3DProductViewModal';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Helmet } from 'react-helmet';
import { domains } from '../assets/domains';

const FolderDetails = () => {

  const { _id, companyId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const ref = query.get("ref")
  const _f = query.get("_f")
  const initialState = {
    emails: null,
    activeTab: "3D",
    displayMode: "grid",
    orderOptions: [
      { column: "latest", label: "Latest" },
      { column: "nameAsc", label: "A - Z" },
      { column: "nameDesc", label: "Z - A" }
    ],
    selectedOrderOption: { column: "latest", label: "Latest" },
    dropdownSplitOpen: false,
    displayOptionsIsOpen: false,
    totalItemCount: 0,
    toggleShareFolderModalVisible: false,
    twoDProductModal: false,
    threeDProduct: {},
    twoDProduct: {},
    twoDProductId: "",
    error: false,
    loading: false,
    totalthreeDItemCount: 0,
    cartProduct: [],
    selectedProduct: [],
    isOpenCartModal: false,
    isOpenEnquiryModal: false,
    enquirySubmitInProcess: false,
    toggleShareCollectionModalVisible: false,
    userEmail: '',
    editId: '',
  };

  const [allState, setAllState] = useState(initialState);
  const [cartProduct, setCartProduct] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [search, setSearch] = useState([]);
  const [folderDetails, setFolderDetails] = useState(null);
  const [companyDetails, setCompanyDetails] = useState(null);
  const [twoDProduct, setTwoDProduct] = useState({});
  const [threeDProduct, setThreeDProduct] = useState({});
  const [notes, setNotes] = useState('');
  const [editId, setEditId] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [twoDProductId, setTwoDProductId] = useState('');
  const [isConformationModal, setIsConformationModal] = useState(false);
  const [isOpenThanksModal, setIsOpenThanksModal] = useState(false);
  const [twoDProductModal, setTwoDProductModal] = useState(false);
  const [threeDProductModal, setThreeDProductModal] = useState(false);

  const [allProducts, setAllProducts] = useState([]);
  const [productCount, setProductCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [enquiryLoader, setEnquiryLoader] = useState(false);

  // SUBMIT_ENQUIRY //
  const [mutateFunction] = useMutation(SEND_INQUIRY_FOLDER_CARTS_PRODUCT);

  // GET ALL FOLDER DATA QUERY //
  const { data: allFolderData, loading: loadingAllFolderData, error: errorAllFolderData } = useQuery(GET_SHARE_FOLDER_ITEMS, {
    variables: {
      name: search,
      folderId: _id,
      companyId,
      sort: "latest",
      offset: offset,
      limit: 40,
    },
  })

  const { data: companyDetail } = useQuery(GET_COMPANY_DETAIL_BY_COMPANY_ID, {
    variables: { companyId: companyId },
    fetchPolicy: "network-only"
  })

  // COMPANY META //
  const { data: companyMeta } = useQuery(GET_COMPANY_META, {
    variables: { companyId: companyId },
    fetchPolicy: "network-only"
  })

  // FOLDER SHARE TRACK //
  const [mutateShareRecordFunction] = useMutation(REGISTER_SHARE_VIEW);

  const newGetShareFolderDetail = () => {
    mutateShareRecordFunction({
      variables: {
        companyId: companyId,
        itemId: _id,
        ref,
        folderPage: true,
      },
      fetchPolicy: "network-only",
    }).then(data => {
    }).catch((error) => {
      navigate('/');
      console.log("error", error)
    })
  }

  const oldRecordAdd = useCallback((data) => {
    setAllProducts((prevProducts) => [...new Set([...prevProducts, ...data])]);
  }, []);

  useEffect(() => {
    if (allFolderData?.getShareFolderData) {
      setFolderDetails(allFolderData?.getShareFolderData?.name);
      setProductCount(allFolderData?.getShareFolderData?.count)
      if (offset === 0) {
        setAllProducts(allFolderData?.getShareFolderData?.data?.map((d) => d?.product))
      } else {
        oldRecordAdd(allFolderData?.getShareFolderData?.data?.map((d) => d?.product))
      }
    }
  }, [allFolderData?.getShareFolderData, offset, oldRecordAdd])

  useEffect(() => {
    if (companyDetail?.getCompanyDetailByCompanyId) {
      setCompanyDetails(companyDetail?.getCompanyDetailByCompanyId);
    }
  }, [companyDetail?.getCompanyDetailByCompanyId])

  useEffect(() => {

    let lsCart = localStorage.getItem('FolderCart');
    let cart = [];
    let cartProduct = [];
    if (JSON.parse(lsCart) && JSON.parse(lsCart)[_id]) {
      cart = JSON.parse(lsCart)[_id]
    } else {
      cart = []
    }
    cartProduct = cart;
    setCartProduct(cartProduct);

    // eslint-disable-next-line
    document.oncontextmenu = new Function("return false;");
    // eslint-disable-next-line
    document.onselectstart = new Function("return false;")

    newGetShareFolderDetail()

    // eslint-disable-next-line
  }, [])

  // MODAL REDIRECTION //
  useEffect(() => {
    const data = location.search;
    const params = new URLSearchParams(data);
    const id = params.get('_pId');
    const selectedProduct = allProducts?.find(d => d._id === id);
    if (selectedProduct && allProducts) {
      setEditId('');
      navigate(`${location.pathname}${location.search}`)
      if (selectedProduct?.folderType === "3D") {
        setThreeDProductModal(true)
        setThreeDProduct(selectedProduct)
      } else {
        setTwoDProductModal(true)
        setTwoDProductId(id)
        setTwoDProduct(selectedProduct)
      }
    }
    // eslint-disable-next-line
  }, [location.search && allProducts])

  useEffect(() => {
    if (allState?.isOpenCartModal) {
      document.body.classList.add('over-flow-hidden');
    }
  }, [allState?.isOpenCartModal])

  useEffect(() => {
    if (!allState?.isOpenCartModal) {
      document.body.classList.remove('over-flow-hidden');
    }
    // eslint-disable-next-line
  }, [!allState?.isOpenCartModal])


  const handleTagChange = (tags) => {
    let allTags = [];
    _.forEach(tags, (tag, index) => {
      allTags = _.concat(allTags, _.split(tag));
    });
    allTags = _.uniq(allTags);
    allTags = allTags.filter(it => it !== "");
    // setOffset(0)
    setSearch(allTags);
  };
  const toggleImageModal = () => {
    setTwoDProductModal(!twoDProductModal)
  }
  const toggleProductModal = () => {
    setThreeDProductModal(!threeDProductModal)
  }

  const isDisabled = (product) => {
    const productDisable = cartProduct?.find(d => d._id === product._id);
    return productDisable ? true : false

  };

  const isChecked = (product) => {
    const productCheck = cartProduct?.find(d => d._id === product._id);
    return productCheck ? true : false
  }

  const nextHandler = (id, val) => {
    const indexVal = allProducts?.findIndex(data => data?._id === id)
    let nextData

    if (indexVal + val >= 0 && indexVal + val !== allProducts?.length) {
      nextData = allProducts[indexVal + val]
    } else {
      nextData = val > 0 ? allProducts[0] : allProducts[allProducts.length - 1]
    }

    const is2D = allProducts?.filter(data => data?.folderType === `2D`)
    if (is2D?.length > 0) {
      setTwoDProductModal(true)
      setThreeDProductModal(false)
      setTwoDProduct(nextData)
      setTwoDProductId(nextData._id)
    } else {
      setTwoDProductModal(false)
      setThreeDProductModal(true)
      setThreeDProduct(nextData)
    }

    navigate(`${location.pathname}?_pid=${nextData?._id}${ref ? `&ref=${ref}` : ''}${_f ? `&_f=${_f}` : ''}`)
  }

  const addToCartItem = (item, fromDetailModal) => {
    let newData;

    if (editId) {
      newData = cartProduct.map(data => {
        if (data._id === editId) {
          return { ...item, notes }
        } else {
          return data
        }
      })
    } else {
      cartProduct.push({ ...item, notes })
      newData = cartProduct
    }

    setCartProduct(newData)
    updateLocalStorageCart(newData)
    if (!editId) {
      // toggleCartModal()
      if (!fromDetailModal) {
        setAllState({ isOpenCartModal: false })
      }
    }
  }

  const toggleConformationModal = () => {
    setIsConformationModal(!isConformationModal)
  }

  const removeProductFromCart = (productDetails) => {
    let newCartProduct = cartProduct.filter(it => it._id !== productDetails._id);
    if (cartProduct && cartProduct?.length) {
      setCartProduct(newCartProduct)
      updateLocalStorageCart(newCartProduct);
    }
  }

  const toggleCartModal = () => {
    setAllState({ isOpenCartModal: !allState.isOpenCartModal })
  }

  const handleNotesChangeInput = (e) => {
    setNotes(e.target.value);
  }

  const updateLocalStorageCart = (cartProducts) => {
    const lsCart = localStorage.getItem('FolderCart');
    const parseData = JSON.parse(lsCart)
    const cart = { ...parseData, [_id]: cartProducts };
    localStorage.setItem('FolderCart', JSON.stringify(cart));
  }

  // SUBMIT ENQUIRY && SEND MAIL //
  const submitEnquiry = (enquiryFiled) => {
    setEnquiryLoader(true)
    setUserEmail(enquiryFiled.email)
    const cartProductIds = cartProduct?.map((d) => d?._id);

    mutateFunction({
      variables: {
        input: {
          companyName: enquiryFiled.companyName,
          firstName: enquiryFiled.firstName,
          lastName: enquiryFiled.lastName,
          email: enquiryFiled.email,
          companyId: companyId,
          FolderId: _id,
          cartProductIds: cartProductIds
        }
      }
    }).then(data => {
      if (data?.data?.folderCartProductsEnquiry) {
        setTwoDProductModal(false);
        setThreeDProductModal(false)
        toggleThanksModal()
      }
      setCartProduct([])
      // updateLocalStorageCart(cartProduct);
      localStorage.removeItem("FolderCart")
      setEnquiryLoader(false)
      setAllState({ isOpenCartModal: false });
    }).catch((error) => {
      throw error;
    })

  }

  const toggleThanksModal = () => {
    setIsOpenThanksModal(!isOpenThanksModal)
  }


  const cartAnimation = (event) => {
    const getClosest = function (elem, selector) {
      for (; elem && elem !== document; elem = elem.parentNode) {
        if (elem.matches(selector)) return elem;
      }
      return null;
    };

    // Start animation block
    let imgToDrag = getClosest(event.target, '.product-card');
    if (!imgToDrag) return;

    let viewCart = document.querySelector('.product-cart');
    if (!viewCart) return;

    let imgToDragImage = imgToDrag.querySelector('.tumb-img');
    if (!imgToDragImage) return;

    let disLeft = imgToDrag.getBoundingClientRect().left;
    let disTop = imgToDrag.getBoundingClientRect().top;
    let cartLeft = viewCart.getBoundingClientRect().left || window.innerWidth;
    let cartTop = viewCart.getBoundingClientRect().top;

    let image = imgToDragImage.cloneNode(true);

    image.style.position = 'fixed';
    image.style.zIndex = '11111';
    image.style.width = '100px';
    image.style.top = `${disTop}px`;
    image.style.left = `${disLeft}px`;
    image.style.transition = 'left 1s, top 1s, width 1s, opacity 1s cubic-bezier(1, 1, 1, 1)';
    image.style.borderRadius = '50px';
    image.style.overflow = 'hidden';
    image.style.boxShadow = '0 21px 36px rgba(0,0,0,0.1)';

    document.body.appendChild(image);

    setTimeout(() => {
      image.style.left = `${cartLeft}px`;
      image.style.top = `${cartTop}px`;
      image.style.width = '40px';
      image.style.opacity = '0';
    }, 100);

    setTimeout(() => {
      if (image && image.parentNode) {
        image.parentNode.removeChild(image);
      }
    }, 1100);  // Extended to ensure it completes after animation
  };

  return (<>
    <div className="navbar folder-share-header px-5 py-3 d-flex company-logo">
      {companyDetails?.logo ? (
        <div>
          <div style={{ width: 110, height: 45 }}>
            <img
              src={`${CLOUD_FRONT_URL}${companyDetails?.logo} `}
              key={"header-logo"}
              alt={"logo"}
              className="h-100"
            // onClick={() => history.push('/')}
            />
          </div>
        </div>

      ) : (
        <a className="navbar-title text-truncate text-primary" href='/'>
          {companyDetails?.name || ""}
        </a>
      )}

      {/* <button onClick={toggleShareCollectionModal} className="btn-black">Share</button> */}
    </div>
    <div className="">
      <Helmet>
        <title>{`${folderDetails ? `${folderDetails} |` : ""} ${domains[window.location.host]["title"]}`}</title>
        <meta id="title-meta" name="title" content={`${folderDetails ? `${folderDetails} |` : ""} | ${domains[window.location.host]["title"]}`} />
        <meta id="description-meta" name="description" content={`${folderDetails ? `${folderDetails} |` : ""} | ${domains[window.location.host]["description"]}`} />
        <link rel="apple-touch-icon" sizes="180x180" href={domains[window.location.host]["appleTouchIcon"]} />
        <link rel="icon" type="image/png" sizes="32x32" href={domains[window.location.host]["icon32"]} />
        <link rel="icon" type="image/png" sizes="16x16" href={domains[window.location.host]["icon16"]} />
        <link rel="manifest" href={domains[window.location.host]["manifest"]} />
        <link rel="mask-icon" color="#666666" href={domains[window.location.host]["maskIcon"]} />
        <link rel="shortcut icon" href={domains[window.location.host]["shortcutIcon"]} />
      </Helmet>
      <div className="disable-text-selection">
        <div xxs="12" style={{ paddingRight: '0px' }}>
          {/* FOLDER TITLE */}
          <div className='py-4 d-flex justify-content-center' style={{ backgroundColor: '#E8E8E8' }}>
            <h3 className='m-0'>
              {folderDetails}
            </h3>
            <div>
            </div>
          </div>
        </div>

        <div className='pt-4'>

          <div className='mx-5'>
            <h4 className='mb-0' style={{ marginTop: '15px' }}>Products ({productCount})</h4>
          </div>

          {/* SEARCH */}
          <Collapse
            className="d-sm-block displayOptions mx-5"
            id="displayOptions"
          >
            <Row>
              <Col sm="12" md="12" lg="12" xl="12" className="d-flex w-100 p-0">
                <div className="search-sm search-main align-self-center flex-grow-1 pb-2 search-wrap">
                  <TagsInput
                    value={search || []}
                    onChange={(e) => {
                      setAllProducts([])
                      setOffset(0)
                      handleTagChange(e)
                    }}
                    inputProps={{ placeholder: "Search" }}
                  />
                </div>
              </Col>
            </Row>
          </Collapse>

          <div>
            {errorAllFolderData && <Col xxs="12" className="text-center my-2" key={0}>
              Error :(
            </Col>}
            {!loadingAllFolderData && !allProducts.length &&
              <Col xxs="12" className="text-center my-2" key={0}>
                No Product Found
              </Col>
            }
          </div>

          <InfiniteScroll
            dataLength={allProducts?.length}
            threshold={4000}
            next={() => { setOffset(offset + 40) }}
            hasMore={allProducts?.length >= 10 && productCount > allProducts?.length}
            loader={<Col xxs="12" className="text-center my-2" key={0}>Loading...</Col>}
          >
            <Row className='px-5'>
              {allProducts?.map((item) => (
                <Col sm="12" md="6" lg="4" xl="3" className="my-3" key={item._id}>
                  <div className="product-box">
                    <Card className={`${selectedProduct?._id === item?._id ? `active` : `default-card`} product-card`}>
                      <Card.Body className="product-box-body">
                        <NavLink to={`${location.pathname}?_pId=${item._id}${ref ? `&ref=${ref}` : ''}${_f ? `&_f=${_f}` : ''}`}>
                          <div className="product-box-image cursor-pointer" onClick={() => {
                            setAllState({ isOpenCartModal: false })
                            setEditId('');
                            if (item?.folderType === `2D`) {
                              setTwoDProductId(item._id)
                              setTwoDProduct(item)
                              setTwoDProductModal(!twoDProductModal)
                            } else {
                              setThreeDProductModal(!threeDProductModal)
                              setThreeDProduct(item)
                            }
                          }}>
                            <Card.Img className='tumb-img' variant="top" src={`${CLOUD_FRONT_URL}${item?.thumb} `}
                              onError={(e) => {
                                e.target.src = defaultImg; // Set the source of the image to the fallback image
                              }}
                            />
                          </div>
                        </NavLink>
                      </Card.Body>
                      <Card.Footer>
                        <div className="d-flex custom-checkbox align-items-center">
                          <Input
                            type="checkbox"
                            disabled={isDisabled(item)}
                            checked={isChecked(item)}
                            onChange={(e) => {
                              cartAnimation(e); // Trigger the animation on checkbox change
                              setTimeout(() => {
                                addToCartItem(item);
                              }, 1000)
                            }}
                          />
                          <div className='ms-3 text-truncate'>
                            <span style={{ fontSize: '15px', fontWeight: '300' }} className='me-1'>{item.sku || item.uniqueCode}</span>
                            <span style={{ fontWeight: '400', }}>{item.name}</span>
                          </div>
                        </div>
                      </Card.Footer>
                    </Card>
                  </div>
                </Col>
              ))}
            </Row>
          </InfiniteScroll>
        </div>

      </div>
    </div>
    {/* 2D PRODUCT VIEWER MODAL */}
    <Folder2DProductViewModal
      visible={twoDProductModal}
      itemProduct={twoDProduct}
      twoDProductId={twoDProductId}
      toggle={toggleImageModal}
      cartProduct={cartProduct}
      setSelectedProduct={setSelectedProduct}
      addToCartItem={(item) => addToCartItem(item, true)}
      removeProductFromCart={removeProductFromCart}
      submitEnquiry={submitEnquiry}
      enquiryLoader={enquiryLoader}
      editNotes={(data) => {
        setSelectedProduct(data);
        setEditId(data?._id);
        setNotes(data?.notes || '');
        toggleConformationModal()
      }}
      closeModal={() => {
        toggleImageModal()
        navigate(`${location.pathname}${ref ? `?ref=${ref}` : ''}${_f ? `&_f=${_f}` : ''}`)
      }}
      nextHandler={(id, val) => {
        setTwoDProductModal(false);
        setThreeDProductModal(false)

        setTimeout(() => {
          nextHandler(id, val)
        }, 400)
      }}
      companyMeta={companyMeta}
      handleNotesChangeInput={handleNotesChangeInput}
      notes={notes}
      setNotes={setNotes}
    />
    {/* 3D PRODUCT VIEWER MODAL */}
    <Folder3DProductViewModal
      visible={threeDProductModal}
      itemProduct={threeDProduct}
      toggle={toggleProductModal}
      cartProduct={cartProduct}
      setSelectedProduct={setSelectedProduct}
      addToCartItem={(item) => addToCartItem(item, true)}
      removeProductFromCart={removeProductFromCart}
      submitEnquiry={submitEnquiry}
      enquiryLoader={enquiryLoader}
      closeModal={() => {
        toggleProductModal()
        navigate(`${location.pathname}${ref ? `?ref=${ref}` : ''}${_f ? `&_f=${_f}` : ''}`)
      }}
      nextHandler={(id, val) => {
        setTwoDProductModal(false);
        setThreeDProductModal(false)

        setTimeout(() => {
          nextHandler(id, val)
        }, 400)
      }}
      companyMeta={companyMeta}
      companyDetails={companyDetails}
      handleNotesChangeInput={handleNotesChangeInput}
      notes={notes}
      setNotes={setNotes}
    />
    {/* CART MODAL  */}
    <CartModal
      toggleCartModal={toggleCartModal}
      isOpenCartModal={allState.isOpenCartModal}
      removeProductFromCart={removeProductFromCart}
      cartProduct={cartProduct}
      editNotes={(data) => {
        setSelectedProduct(data);
        setEditId(data?._id);
        setNotes(data?.notes || '');
        toggleConformationModal()
      }}
      submitEnquiry={submitEnquiry}
      enquiryLoader={enquiryLoader}
    />
    {/* CONFORMATION MODAL */}
    <Modal Modal onHide={toggleConformationModal} show={isConformationModal} className="add-modal comment-modal">
      <Modal.Header>
        <span className="m-auto">Add to Selection</span>
        <span className="modal-close" onClick={toggleConformationModal}>
          <Close />
        </span>
      </Modal.Header>
      <Modal.Body>
        <div className="add-modal-product">
          <img
            key={selectedProduct._id}
            src={`${CLOUD_FRONT_URL}${selectedProduct.thumb} `}
            alt={selectedProduct.name}
            className="card-img-bottom"
            style={{ border: '1px solid #F6F6F6' }}
          />
          <div className="add-modal-product-details w-100">
            <div className="add-modal-product-details-title">
              {selectedProduct.name}
            </div>
            <div className="form">
              <Form>
                <Form.Label>Notes</Form.Label>
                <FloatingLabel controlId="floatingTextarea">
                  <Form.Control
                    as="textarea"
                    placeholder="Please write any question regards to Set of 3 Candle Stand product..."
                    rows={8}
                    onChange={handleNotesChangeInput}
                    value={notes}
                  />
                </FloatingLabel>
              </Form>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center gap-3">
          <Button variant="primary" type="submit" className="btn-black" onClick={() => {
            addToCartItem(selectedProduct)
            toggleConformationModal()
          }}>
            Submit
          </Button>
          <Button
            variant="primary"
            type="submit"
            className="btn-gray"
            onClick={toggleConformationModal}
          >
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
    {/* THANKS MODAL */}
    <Modal Modal show={isOpenThanksModal} centered >
      <Modal.Header className="thanks-modal-header">
        <span className="modal-close cursor-pointer" onClick={toggleThanksModal}>
          <Close />
        </span>
      </Modal.Header>
      <Modal.Body className="thanks-modal-body">
        <img src={thankYouImg} alt="" />
        <span>Thank you for inquiry!</span>
        <p>We will reach out to you with requested details shortly at your <span>{userEmail}</span></p>
      </Modal.Body>
    </Modal>
    {/* CART BUTTON */}
    <div div className="selected-product" onClick={toggleCartModal} style={{ display: allState.isOpenCartModal ? 'none' : 'block' }}>
      <div className="selected-product-wrap">
        <svg xmlns="http://www.w3.org/2000/svg" width="42.851" height="42.851" viewBox="0 0 42.851 42.851">
          <g id="tick" transform="translate(-1196.047 -324.156)">
            <circle id="Ellipse_1159" data-name="Ellipse 1159" cx="21.426" cy="21.426" r="21.426" transform="translate(1196.047 324.156)" fill="#202027" />
            <path id="Path_314" data-name="Path 314" d="M4.651,80.971a1.539,1.539,0,0,1-1.09-.452L.266,77.225a.908.908,0,0,1,0-1.284h0a.908.908,0,0,1,1.284,0l3.1,3.1,8.2-8.2a.908.908,0,0,1,1.284,0h0a.908.908,0,0,1,0,1.284l-8.4,8.4A1.539,1.539,0,0,1,4.651,80.971Z" transform="translate(1210.574 269.563)" fill="#fff" stroke="#fff" strokeWidth="0.4" />
          </g>
        </svg>

        <div className='product-cart'>
          <p style={{ textWrap: 'nowrap' }}>View selection</p>
          <p>{cartProduct?.length} items</p>
        </div>
      </div>
    </div>
  </>);
}

export default FolderDetails