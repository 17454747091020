import { CLOUD_FRONT_URL } from "../../config";

export const getProductJSON = ({
  product,
  companyMetaDetails,
  companyDetails,
}) => {
  let productData = JSON.parse(JSON.stringify(product));

  productData.f = productData?.fbxUrl;
  productData.g = productData?.gltfUrl;
  productData.sg = productData?.styledGltfUrl;
  productData.u = productData?.usdzUrl;
  productData.uc = productData?.uniqueCode;
  productData.mp = productData?.modelPosition;
  productData.mnpa = productData?.minPolarAngle;
  productData.mxpa = productData?.maxPolarAngle;
  productData.zl = productData?.zoomLock;

  if (!!companyMetaDetails?.ibl) {
    productData.ib = !!companyMetaDetails?.ibl;
  }

  if (productData?.envMap && productData?.envMap?.length) {
    productData.em = productData.envMap;
  } else if (companyDetails?.envMap && companyDetails?.envMap?.length) {
    productData.em = companyDetails?.envMap;
  }

  if (productData?.styleLights && productData?.styleLights.length) {
    productData.sl = productData?.styleLights;
  } else if (
    companyMetaDetails?.styleLights &&
    companyMetaDetails?.styleLights.length
  ) {
    productData.sl = companyMetaDetails?.styleLights;
  }

  if (
    productData?.brightnessValue !== null &&
    productData?.brightnessValue !== undefined &&
    productData?.brightnessValue !== ""
  ) {
    productData.bv = productData.brightnessValue;
  } else if (
    companyMetaDetails?.brightnessValue !== null &&
    companyMetaDetails?.brightnessValue !== undefined &&
    companyMetaDetails?.brightnessValue !== ""
  ) {
    productData.bv = companyMetaDetails?.brightnessValue;
  }

  if (companyMetaDetails?.hemisphereLight) {
    productData.hl = companyMetaDetails?.hemisphereLight;
  }

  if (companyMetaDetails?.ambientLight) {
    productData.al = companyMetaDetails?.ambientLight;
  }

  if (productData?.hl) {
    let newHL = {};
    newHL.i = productData.hl.intensity;
    newHL.gc = productData.hl.groundColor;
    newHL.x = productData.hl.positionX;
    newHL.y = productData.hl.positionY;
    newHL.z = productData.hl.positionZ;
    productData.hl = newHL;
  }

  if (productData?.al) {
    let newAL = {};
    newAL.i = productData.al.intensity;
    productData.al = newAL;
  }

  if (productData?.sl && productData?.sl?.length) {
    productData.sl = productData.sl.map((l) => {
      let returnObject = {
        n: l.name,
        _id: l._id,
        t: l.type,
        v: l.visible,
        a: l.active,
        i: l.intensity,
      };

      if (l?.position) {
        returnObject.p = {
          x: l.position.x,
          y: l.position.y,
          z: l.position.z,
        };
      }

      if (l?.distance) {
        returnObject.d = l.distance;
      }

      if (l?.decay) {
        returnObject.dc = l.decay;
      }

      if (l?.angle) {
        returnObject.an = l.angle;
      }

      if (l?.pn) {
        returnObject.pn = l.penumbra;
      }

      return returnObject;
    });
  }

  productData.p = productData?.parts?.map((p) => {
    return {
      p: p?.parts,
      a: p?.name,
      h: p?.hexCode,
      d: p?.diffuse,
      n: p?.normal,
      m: p?.metalness,
      r: p?.roughness,
      t: p?.transparency,
      pd: p?.patternDiffuse,
      ap: p?.isAlphaPattern,
      at: p?.alphaTest,
      rpx: p?.repeatX,
      rpy: p?.repeatY,
      ofsx: p?.offsetX,
      ofsy: p?.offsetY,
      rot: p?.rotate,
      dm: p?.displacement,
      ds: p?.displacementScale,
      db: p?.displacementBias,
      ao: p?.ao,
      aoi: p?.aoValue,
    };
  });

  delete productData?.parts;
  delete productData?.fbxUrl;
  delete productData?.gltfUrl;
  delete productData?.styledGltfUrl;
  delete productData?.usdzUrl;
  delete productData?.brightnessValue;
  delete productData?.envMap;
  delete productData?.styleLights;
  delete productData?.uniqueCode;
  delete productData?.modelPosition;
  delete productData?.minPolarAngle;
  delete productData?.maxPolarAngle;
  delete productData?.zoomLock;

  if (productData && productData?.thumb) {
    productData.thumb = `${CLOUD_FRONT_URL}${productData.thumb}`;
  }

  if (
    productData &&
    productData.spin360Renders &&
    productData.spin360Renders.length
  ) {
    productData.detailView =
      productData.spin360Renders[productData.spin360Renders.length - 1].detailView;
    delete productData.spin360Renders;
  }

  return productData;
};