import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { Helmet } from 'react-helmet';
import { useLocation, useParams } from 'react-router-dom';
import ProductImages from "../components/ProductImages";
import ProductDetails from "../components/ProductDetails";
import { GET_COMPANY_META, GET_IMAGE_DETAILS } from './query'
import { domains } from "../assets/domains";
import PageNotFound from "./PageNotFound";
import loader from "../assets/loader.png"

const ProductDetailsPage = () => {
  const { _id, companyId } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const ref = searchParams.get('ref');
  const [imageDetailObject, setImageDetailObject] = useState()
  const [companyMetaData, setCompanyMetaData] = useState()
  // convert use lazy query convert 
  const { data, loading } = useQuery(GET_IMAGE_DETAILS, {
    variables: { data: { _id, ref } },
    fetchPolicy: "cache-and-network",
  })

  const { data: companyMeta, loading: loadingCompany } = useQuery(GET_COMPANY_META, {
    variables: { companyId: companyId },
    fetchPolicy: "cache-and-network",
  })

  useEffect(() => {
    if (data?.getImageDetailViewById) {
      setImageDetailObject(data?.getImageDetailViewById?.[0])
    }
  }, [data])

  useEffect(() => {
    if (companyMeta?.getCompanyMeta?.generalSettings?.additionalImageFieldSettings) {
      setCompanyMetaData(companyMeta?.getCompanyMeta?.generalSettings?.additionalImageFieldSettings)
    }
  }, [companyMeta])

  return (
    <>
      {companyId && domains[window.location.host] && domains[window.location.host]["companyId"] === companyId ?
        <div className="product">
          {(loading || loadingCompany) && <img className="loader-image" src={loader} alt="Loading" />}
          <Helmet>
            <title>{domains[window.location.host]["title"]}</title>
            <meta id="title-meta" name="title" content={domains[window.location.host]["title"]} />
            <meta id="description-meta" name="description" content={domains[window.location.host]["description"]} />
            <link rel="apple-touch-icon" sizes="180x180" href={domains[window.location.host]["appleTouchIcon"]} />
            <link rel="icon" type="image/png" sizes="32x32" href={domains[window.location.host]["icon32"]} />
            <link rel="icon" type="image/png" sizes="16x16" href={domains[window.location.host]["icon16"]} />
            <link rel="manifest" href={domains[window.location.host]["manifest"]} />
            <link rel="mask-icon" color="#666666" href={domains[window.location.host]["maskIcon"]} />
            <link rel="shortcut icon" href={domains[window.location.host]["shortcutIcon"]} />
          </Helmet>
          {imageDetailObject && <>
            <ProductImages imageDetailObject={imageDetailObject} images={[...[{ image: imageDetailObject?.image, thumb: imageDetailObject?.thumb }], ...imageDetailObject?.angleImages]} />
            <ProductDetails data={imageDetailObject} companyMeta={companyMetaData} />
          </>}
        </div>
        : <PageNotFound />
      }
    </>
  );
};

export default ProductDetailsPage;
