import { gql } from "@apollo/client";
export const GET_IMAGE_DETAILS = gql`
query getImageDetailViewById($data: getObject) {
  getImageDetailViewById(data: $data) {
    _id
    key
    name
    uniqueCode
    isSetIOf3tem
    isSetIOf3Data
    sku
    description
    productStyleId
    tags
    image
    thumb
    detailView
    companyId
    companyName
    companyLogo
    shareId
    createdAt
    modifiedAt
    createdByUserName
    modifiedByUserName
    emails {
      _id
      email
      message
      sentAt
      shortId
      active
      expirationDate
      __typename
    }
    angleImages {
      _id
      name
      image
      thumb
      detailView
      __typename
    }
    specification {
      unit
      length
      width
      height
      __typename
    }
    packageDimension {
      unit
      length
      width
      height
      __typename
    }
    cbm
    size
    materials
    finishes
    isDemoProduct
    priceLabel
    priceValue
    spin360
    polyFoundryVisible
    featuredProduct
    guestProduct
    storeCategories {
      _id
      title
      slug
      type
      icon
      parentCategoryId
      __typename
    }
    storeSubCategories {
      _id
      title
      slug
      type
      icon
      parentCategoryId
      __typename
    }
    storeMaterials {
      _id
      title
      slug
      type
      icon
      parentCategoryId
      __typename
    }
    materialCategories {
      _id
      name
      materialId
      materialName
      __typename
    }
    config {
      currency {
        _id
        name
        symbol
        defaultSelected
        createdAt
        modifiedAt
        __typename
      }
      currencyConversions {
        _id
        name
        symbol
        value
        rate
        __typename
      }
      fieldSettings {
        showPackageDimension
        showIpMp
        showIpMpDimension
        showEnquireNowBtn
        showPrice
        __typename
      }
      __typename
    }
    ip
    mp
    ipMpUnit
    ipDimension{
      unit
      length
      width
      height
      __typename
    }
    ipCBM
    mpDimension{
      unit
      length
      height
      width
      __typename
    }
    mpCBM
    spin360Renders{
      _id
      thumb
    	detailView
      renderId
      spin360
      __typename
    }
    __typename
  }
}
`

export const SUBMIT_ENQUIRY = gql`
mutation mutationSendEnquiry($id: String!, $enquiryData: EnquiryDataObject) {
  imageEnquiry(id: $id, enquiryData: $enquiryData) {
    message
    __typename
  }
}
`

export const GET_COMPANY_META = gql`
query getMeta($companyId: String){
  getCompanyMeta(companyId:$companyId){
    _id
    companyName
    companyId
    generalSettings{
      _id
      authenticateShareViaEmail
      additionalImageFieldSettings{
        showPackageDimension
        showIpMp
        showEnquireNowBtn
        showPrice
        _id
      }	
    }
  }
}
`

export const SEND_INQUIRY_FOLDER_CARTS_PRODUCT = gql`
  mutation folderCartProductsEnquiry($input: folderProductCart!) {
    folderCartProductsEnquiry(input: $input) {
      message
      rejected
    }
  }
  `

const RenderedImagePayload = `
  {
    _id
    thumb
    detailView
    renderId
  }
`;

const Spin360RendersPayload = `
{
  _id
  thumb
  detailView
  renderId
  spin360
}
`;

const partObject = `
    {
      _id
      partId: _id
      parts
      materialCategory
      materialCategoryName
      materialName
      name
      isCustomize
      materialId
      pantone
      hexCode
      colorType
      finishId
      finishName
      diffuse
      metallicRoughness
      finishThumb
      normal
      metalness
      ao
      aoValue
      roughness
      transparency
      patternId
      patternName
      patternDiffuse
      customiseFinishIds
      transparencyValue
      subFinishDiffuse
      isAlphaPattern
      alphaTest
      alphaDiffuse
      shapeId
      shapes {
        _id
        name
        objUrl
        gltfUrl
        fbxUrl
        thumb
        groupId
      }
      limitedFinishes
      showInShare
      displacement
      displacementScale
      displacementBias
      repeatX
      repeatY
      offsetX
      offsetY
      rotate
    }
`;

const DefaultViewerSettingsPayload = `
{
  _id
  shadowEffect
  viewer
  viewerWebPSupport
  styleLights {
    _id
    name
    position {
      _id
      x
      y
      z
    }
    visible
    type
    intensity
    distance
    decay
    angle
    penumbra
  }
  ibl
  ambientLight {
    intensity
  }
  hemisphereLight {
    positionX
    positionY
    positionZ
    groundColor
  }
  brightnessValue
  backgroundImage
  zoomLock
  showDownloadButton
  showRulerButton
  showFullScreenButton
  shareViewer
  threeDProductEditViewer
  metalness
  loaderColor
  backgroundColor
}
`;

const productStyleDetail = `
_id
name
objUrl
fbxUrl
gltfUrl
usdzUrl
styledGltfUrl
status
category
description
isExclusive
selectedCompany
detailView
thumb
companyId
uniqueCode
sku
createdAt
createdByUserName
modifiedByUserName
productStyleId
modifiedAt
parts ${partObject}
emails {
  _id
  email
  message
  sentAt
  shortId
  active
}
shareId
tags
companyName
companyLogo
cbm
size
supplierId
renderedImages ${RenderedImagePayload}
spin360Renders ${Spin360RendersPayload}
partsMaterial {
  part
  material
}
sketchFabId
noStyle
specification {
  height
  width
  length
}
isDemoProduct
isAccessoriesProduct
shadowEffect
customizedSettings
viewer
viewerWebPSupport
priceLabel
priceValue
envMap
brightnessValue
zoomLock
styleLights {
  _id
  name
  position {
    _id
    x
    y
    z
  }
  visible
  type
  intensity
  distance
  decay
  angle
  penumbra
}
ibl
ambientLight {
  intensity
}
hemisphereLight {
  positionX
  positionY
  positionZ
  groundColor
}
backgroundImage
shareViewer
threeDProductEditViewer
defaultViewerSettings ${DefaultViewerSettingsPayload}
gaTag
modelPosition {
  _id
  x
  y
  z
}
minPolarAngle
maxPolarAngle
hideLiveChatFromShare
selectedProductAttribute
`;

export const REGISTER_SHARE_VIEW = gql`
  mutation create(
    $itemId: String,	
    $companyId: String,	
    $ref: String, 
    $companyName: String,	
    $firstName: String,	
    $lastName: String,	
    $email: String,
    $embed: Boolean,
    $folderPage: Boolean,
  ) {
    registerShareView(
      itemId: $itemId,	
      companyId: $companyId,	
      ref: $ref, 
      companyName: $companyName,	
      firstName: $firstName,	
      lastName: $lastName,	
      email: $email,
      embed: $embed,
      folderPage: $folderPage,
    ) {
      product { ${productStyleDetail} }
    }
  }
`;
