import React, { useState } from "react";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import Tabs from "react-bootstrap/Tabs";

import OrderEnquiry from "../modals/OrderEnquiry";
//import p1 from "../assets/images/products/p1.png";

// import { ReactComponent as Share } from "../assets/images/icons/share.svg";
import { ReactComponent as Close } from "../assets/images/icons/close.svg";
import ProductGallary from "./GallarySlider";
import { CLOUD_FRONT_URL } from "../config";
import { useEffect } from "react";
import { round } from 'lodash';

const ProductDetails = ({ data, companyMeta }) => {
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [titleAsPerUnit, setTitleAsPerUnit] = useState("inches");
  const [price, setPrice] = useState({
    priceLabel: '',
    priceValue: ''
  });
  const [allCurrency, setAllCurrency] = useState([])
  const [multipleData, setMultipleData] = useState([])
  const [singleData, setSingleData] = useState([])
  const [showDimesion, setShowDimesion] = useState(false)
  const [productCurrentUnit, setProductCurrentUnit] = useState('in')
  const [cartoonCurrentUnit, setCartoonCurrentUnit] = useState('in')
  const [ipUnit, setIpnit] = useState('in')
  const [mpUnit, setMpUnit] = useState('in')

  const handleAddClick = () => {
    setAddModalOpen(true);
  };

  const handleClose = () => {
    setAddModalOpen(false);
  };

  const converter_inch_cm = (dimension) => {
    const { unit, width, height, length } = dimension;
    let dimensions = [dimension];
    const convertToCm = (value) => {
      return Math.round(value / 0.3937);
    };

    const convertToInch = (value) => {
      return Math.round(value * 0.3937);
    };

    if (unit === "cm") {
      // convert to inches
      dimensions.push({
        unit: "inches",
        width: convertToInch(width),
        height: convertToInch(height),
        length: convertToInch(length),
      });
    } else if (unit === "inches") {

      // convert to cm
      dimensions.push({
        unit: "cm",
        width: convertToCm(width),
        height: convertToCm(height),
        length: convertToCm(length),
      });
    }

    return dimensions;
  };

  const converter_inch_cm1 = (dimension) => {
    const { unit, width, height, length } = dimension;
    let dimensions = [dimension];
    const convertToCm = (value) => {
      let toFixVal = value / 0.3937
      let decimal = +(toFixVal).toString().split(".")[0]
      // let point = +(toFixVal).toString().split(".")[1][0]
      let point;
      if (typeof toFixVal !== 'undefined' && toFixVal.toString().split(".").length > 1) {
        point = +(toFixVal.toString().split(".")[1][0]);
      }
      toFixVal = point === 5 ? +(decimal + "." + point) : Math.round(toFixVal)
      return toFixVal
    };

    const convertToInch = (value) => {
      let toFixVal = (value * 0.3937)
      let decimal = +(toFixVal).toString().split(".")[0]
      let point = +(toFixVal).toString().split(".")[1][0]
      toFixVal = point === 5 ? +(decimal + "." + point) : Math.round(toFixVal)
      return toFixVal;
    };

    if (unit === "cm") {
      // convert to inches
      dimensions.push({
        unit: "inches",
        width: convertToInch(width),
        height: convertToInch(height),
        length: convertToInch(length),
      });
    } else if (unit === "inches") {

      // convert to cm
      dimensions.push({
        unit: "cm",
        width: convertToCm(width),
        height: convertToCm(height),
        length: convertToCm(length),
      });
    }

    return dimensions;
  };

  useEffect(() => {
    if (data) {
      let allCurrencyData = []
      if (data?.priceValue) {
        allCurrencyData.push({ ...data?.config?.currency, rate: data?.priceValue })
      }
      if (data.config && data.config.currencyConversions && data.config.currencyConversions.length) {
        data.config.currencyConversions.forEach((currency) => {
          allCurrencyData.push({
            name: currency.name,
            symbol: currency.symbol,
            rate: (currency.rate * data.priceValue).toFixed(2)
          })
        })
      }
      setPrice({
        priceLabel: data?.config?.currency?.symbol,
        priceValue: data?.priceValue
      })
      setAllCurrency(allCurrencyData)
      const ipDimensionShow = data?.config?.fieldSettings?.showIpMpDimension || false
      const ipMPShow = data?.config?.fieldSettings?.showIpMp || false

      const setOf3Data = data?.isSetIOf3Data
      let multipleDataTemp = [];
      let singleDataTemp = []
      const keyNameChange = {
        setName: 'Set Name',
        productName: 'Product Name',
        sku: 'SKU',
        price: 'Price',
        category: 'Category',
        material: 'Material',
        finish: 'Finish',
        masterPack: 'Master Pack',
        description: 'Description',
      }
      const ArrayKey = ['category', 'material', 'finish']
      if (setOf3Data) {
        for (const key in setOf3Data?.mainDetails) {
          const filterLenght = setOf3Data?.mainDetails[key]?.filter(data => data)
          if (filterLenght.length > 1) {
            if (!['setName', 'price', 'pricecurrency']?.includes(key)) {
              if (ArrayKey.includes(key)) {
                const joinData = setOf3Data?.mainDetails[key]?.map(mapData => mapData?.filter(data => data)?.map(data => data?.label)?.filter(data => data)?.join(', '))
                multipleDataTemp = [...multipleDataTemp, { key: keyNameChange[key] || key, value: joinData || '-' }]
              } else {
                multipleDataTemp = [...multipleDataTemp, { key: keyNameChange[key] || key, value: setOf3Data?.mainDetails[key] }]
              }
            }
          } else {
            if (!['setName', 'price', 'pricecurrency']?.includes(key)) {
              if (ArrayKey.includes(key)) {
                const data1 = setOf3Data?.mainDetails[key]?.[0]
                const joinData = data1 && typeof data1 !== 'string' ? data1?.filter(data => data)?.map(data => data?.label)?.filter(data => data) : []
                singleDataTemp = [...singleDataTemp, { key: keyNameChange[key] || key, value: joinData?.join(', ') || '-' }]
              } else {
                singleDataTemp = [...singleDataTemp, { key: keyNameChange[key] || key, value: setOf3Data?.mainDetails[key]?.join(', ') || '-' }]
              }
            }
          }
        }
        for (const key in setOf3Data?.attributeDetails) {
          const filterLenght = setOf3Data?.attributeDetails[key]?.filter(data => data)
          if (filterLenght.length > 1) {
            multipleDataTemp = [...multipleDataTemp, { key: key, value: setOf3Data?.attributeDetails[key] }]
          } else {
            singleDataTemp = [...singleDataTemp, { key: key, value: setOf3Data?.attributeDetails[key]?.join(', ') || '-' }]
          }
        }
        setProductCurrentUnit((prevData) => prevData || setOf3Data?.productDimentions?.[0]?.Unit || 'in')
        setCartoonCurrentUnit((prevData) => prevData || setOf3Data?.cartoonDimentions?.[0]?.Unit || 'in')
        setIpnit((prevData) => prevData || setOf3Data?.ipDimentions?.[0]?.Unit || 'in')
        setMpUnit((prevData) => prevData || setOf3Data?.mpDimentions?.[0]?.Unit || 'in')
      }
      if (!ipMPShow) {
        singleDataTemp = singleDataTemp?.filter(data => !["Inner Pack", "Master Pack"].includes(data.key))
      }
      setShowDimesion(ipDimensionShow)
      setSingleData(singleDataTemp)
      setMultipleData(multipleDataTemp)

    }
  }, [data])

  const handleCurrencyChange = (e) => {
    const matchCurrency = allCurrency?.find((d) => (
      d?.name === e?.target?.value
    ))
    setPrice({
      priceLabel: matchCurrency?.symbol,
      priceValue: matchCurrency?.rate
    })
  }

  const handleTabSelect = (val) => {
    setTitleAsPerUnit(val)
  }

  const floorTheDimensionValue = (value) => {
    if (value.toString().includes('.5')) {
      return (Math.floor(value) + 0.5).toString();
    } else {
      return Math.round(value).toString();
    }
  }
  const generateOutput = (l, w, h) => {
    let output = "";
    if (l) {
      output += `${l}L`;
      if (w || h) {
        output += " X ";
      }
    }
    if (w) {
      output += `${w}W`;
      if (h) {
        output += " X ";
      }
    }
    if (h) {
      output += `${h}H`;
    }
    return output;
  }
  const convertDimentions = (specification, unit, newUnit) => {
    let l = specification["length"];
    let w = specification["width"];
    let h = specification["height"];
    if (l || w || h) {
      if (unit === newUnit) {
        // return `${l ? `${l} L` : ''} ${l && w ? " X " : ""} ${w ? `${w} W` : ''} ${h && w ? " X " : ""}${h ? `${h} H` : ''}`.trim();
        return generateOutput(l, w, h)
      } else {
        if (["cm", 'CM', 'CMS'].includes(unit) && ['inches', 'IN', 'INCH', 'in'].includes(newUnit)) {
          const lValue = l ? floorTheDimensionValue(round((parseFloat(l) / 2.54), 2)) : ''
          const wValue = w ? floorTheDimensionValue(round((parseFloat(w) / 2.54), 2)) : ''
          const hValue = h ? floorTheDimensionValue(round((parseFloat(h) / 2.54), 2)) : ''
          return generateOutput(lValue, wValue, hValue)
        } else if (['inches', 'IN', 'INCH', 'in'].includes(unit) && ["cm", 'CM', 'CMS'].includes(newUnit)) {
          const lValue = l ? floorTheDimensionValue(round((parseFloat(l) * 2.54), 2)) : ''
          const wValue = w ? floorTheDimensionValue(round((parseFloat(w) * 2.54), 2)) : ''
          const hValue = h ? floorTheDimensionValue(round((parseFloat(h) * 2.54), 2)) : ''
          return generateOutput(lValue, wValue, hValue)
        } else {
          return generateOutput(l, w, h)
        }
      }
    }
  }

  return (
    <>
      <div className="product-details">
        <div className="product-details-body">
          <div className="product-details-body-close">
            <Close />
          </div>
          <div className="product-details-header">
            <div>
              {data?.companyLogo ? (
                <Link to="/">
                  <img
                    src={`${CLOUD_FRONT_URL}${data?.companyLogo}`}
                    alt={data?.companyName}
                    title={data?.companyName}
                    className="product-details-logo logo"
                  />
                </Link>
              ) : null}
              <h6 className="product-details-tagline">{data?.companyName}</h6>
            </div>
          </div>
          <p className="product-details-sku">{data?.sku}</p>
          <h1 className="product-details-title">{data?.name}</h1>
          {data?.isSetIOf3tem ? <div>
            {/* <Row className="d-flex " style={{ marginLeft: 5, paddingBottom: 10 }}>
              {data?.isSetIOf3Data?.mainDetails?.setName?.map((mainData, index) => {
                const value = data?.isSetIOf3Data?.mainDetails?.price[index]
                return <Col xxl={4} xl={4} lg={4} md={12} sm={12} xs={12} xxs={12} className="mt-2 p-0">
                  <div style={{ padding: "10px 20px", borderRadius: 50, background: '#fdf0f7', marginRight: 10, display: 'flex' }}>
                    {mainData} <div className="pink-color ms-1">{value ? ` $${value}` : ''}</div>
                  </div>
                </Col>
              })}
            </Row> */}

            <div className='d-flex flex-wrap mb-3'>
              {data?.isSetIOf3Data?.mainDetails?.setName?.map((data, index) => {
                const value = data?.isSetIOf3Data?.mainDetails?.price[index]
                const currency = data?.isSetIOf3Data?.mainDetails?.pricecurrency?.[0]?.value || '$'
                return <div className="mt-2 pl-0">
                  <div style={{ padding: "10px 20px", borderRadius: 50, background: '#fdf0f7', marginRight: 10, display: 'inline-block' }}>
                    {data} <span className="pink-color ml-1">{value ? ` ${currency}${value}` : ''}</span>
                  </div>
                </div>
              })}
            </div>

            {/* Product Dimensions card */}
            <div className="card pb-0 pt-3 ps-3 pe-3 pb-0" style={{ marginLeft: 5, borderRadius: '3%' }}>
              <div className="title d-flex flex-row justify-content-between">
                <h6 className="font-weight-semibold d-flex w-100 m-0 mb-2">
                  Product Dimensions
                </h6>
                <div className="select-custom d-flex mb-1" style={{ flexWrap: 'nowrap' }}>
                  <div
                    className={`me-2  cursor-pointer ${productCurrentUnit === 'in' ? 'active-dimension' : 'deActive-dimension'}`}
                    onClick={() => {
                      setProductCurrentUnit('in')
                    }}
                  >IN</div>
                  <div
                    className={`me-2  cursor-pointer ${productCurrentUnit === 'cm' ? 'active-dimension' : 'deActive-dimension'}`}
                    onClick={() => {
                      setProductCurrentUnit('cm')
                    }}>CM</div>
                </div>
              </div>
              <div className='border-bottom mb-4'></div>
              {data?.isSetIOf3Data?.mainDetails?.setName?.slice(0, data?.isSetIOf3Data?.productDimentions?.length)?.map((resData, index) => {
                const sizeFiled = data?.isSetIOf3Data?.productDimentions?.[index]
                const sizeUnit = data?.isSetIOf3Data?.productDimentions?.[0]?.Unit?.value || ''
                const specification = {
                  length: sizeFiled?.L || '',
                  width: sizeFiled?.W || '',
                  height: sizeFiled?.H || '',
                }
                const finalSize = convertDimentions(specification, sizeUnit, productCurrentUnit, "")
                return <>
                  <div className="d-flex justify-content-between">
                    <div>{resData}</div>
                    <div>{sizeFiled && finalSize ? `${finalSize} ${productCurrentUnit.toUpperCase()}` : '-'}</div>
                  </div>
                  <div className='border-bottom mb-4'></div>
                </>
              })}
            </div>
            {/* Cartoon Dimensions card */}
            <div className="card pb-0 pt-3 ps-3 pe-3 pb-0 mt-3" style={{ marginLeft: 5, borderRadius: '3%' }}>
              <div className="title d-flex flex-row justify-content-between">
                <h6 className="font-weight-semibold d-flex w-100 m-0 mb-2">
                  Cartoon Dimensions
                </h6>
                <div className="select-custom d-flex mb-1" style={{ flexWrap: 'nowrap' }}>
                  <div
                    className={`me-2  cursor-pointer ${cartoonCurrentUnit === 'in' ? 'active-dimension' : 'deActive-dimension'}`}
                    onClick={() => {
                      setCartoonCurrentUnit('in')
                    }}
                  >IN</div>
                  <div
                    className={`me-2  cursor-pointer ${cartoonCurrentUnit === 'cm' ? 'active-dimension' : 'deActive-dimension'}`}
                    onClick={() => {
                      setCartoonCurrentUnit('cm')
                    }}>CM</div>
                </div>
              </div>
              <div className='border-bottom mb-4'></div>
              {data?.isSetIOf3Data?.mainDetails?.setName?.slice(0, data?.isSetIOf3Data?.cartoonDimentions?.length)?.map((resData, index) => {
                const sizeFiled = data?.isSetIOf3Data?.cartoonDimentions?.[index]
                const sizeUnit = data?.isSetIOf3Data?.cartoonDimentions?.[0]?.Unit?.value || ''
                const specification = {
                  length: sizeFiled?.L || '',
                  width: sizeFiled?.W || '',
                  height: sizeFiled?.H || '',
                }

                const finalSize = convertDimentions(specification, sizeUnit, cartoonCurrentUnit, "")

                return <>
                  <div className="d-flex justify-content-between">
                    <div>{resData}</div>
                    <div>{sizeFiled && finalSize ? `${finalSize} ${cartoonCurrentUnit.toUpperCase()}` : '-'}</div>
                  </div>
                  <div className='border-bottom mb-4'></div>
                </>
              })}
            </div>
            {/* Ip Dimensions card */}
            {showDimesion && <>
              <div className="card pb-0 pt-3 ps-3 pe-3 pb-0 mt-3" style={{ marginLeft: 5, borderRadius: '3%' }}>
                <div className="title d-flex flex-row justify-content-between">
                  <h6 className="font-weight-semibold d-flex w-100 m-0 mb-2">
                    IP Dimensions
                  </h6>
                  <div className="select-custom d-flex mb-1" style={{ flexWrap: 'nowrap' }}>
                    <div
                      className={`me-2  cursor-pointer ${ipUnit === 'in' ? 'active-dimension' : 'deActive-dimension'}`}
                      onClick={() => {
                        setIpnit('in')
                      }}
                    >IN</div>
                    <div
                      className={`me-2  cursor-pointer ${ipUnit === 'cm' ? 'active-dimension' : 'deActive-dimension'}`}
                      onClick={() => {
                        setIpnit('cm')
                      }}>CM</div>
                  </div>
                </div>
                <div className='border-bottom mb-4'></div>
                {data?.isSetIOf3Data?.mainDetails?.setName?.slice(0, data?.isSetIOf3Data?.ipDimentions?.length)?.map((resData, index) => {
                  const sizeFiled = data?.isSetIOf3Data?.ipDimentions?.[index]
                  const sizeUnit = data?.isSetIOf3Data?.ipDimentions?.[0]?.Unit?.value || ''
                  const specification = {
                    length: sizeFiled?.L || '',
                    width: sizeFiled?.W || '',
                    height: sizeFiled?.H || '',
                  }
                  const finalSize = convertDimentions(specification, sizeUnit, ipUnit, "")

                  return <>
                    <div className="d-flex justify-content-between">
                      <div>{resData}</div>
                      <div>{sizeFiled && finalSize ? `${finalSize} ${ipUnit.toUpperCase()}` : '-'}</div>
                    </div>
                    <div className='border-bottom mb-4'></div>
                  </>
                })}
              </div>
              <div className="card pb-0 pt-3 ps-3 pe-3 pb-0 mt-3" style={{ marginLeft: 5, borderRadius: '3%' }}>
                <div className="title d-flex flex-row justify-content-between">
                  <h6 className="font-weight-semibold d-flex w-100 m-0 mb-2">
                    MP Dimensions
                  </h6>
                  <div className="select-custom d-flex mb-1" style={{ flexWrap: 'nowrap' }}>
                    <div
                      className={`me-2  cursor-pointer ${mpUnit === 'in' ? 'active-dimension' : 'deActive-dimension'}`}
                      onClick={() => {
                        setMpUnit('in')
                      }}
                    >IN</div>
                    <div
                      className={`me-2  cursor-pointer ${mpUnit === 'cm' ? 'active-dimension' : 'deActive-dimension'}`}
                      onClick={() => {
                        setMpUnit('cm')
                      }}>CM</div>
                  </div>
                </div>
                <div className='border-bottom mb-4'></div>
                {data?.isSetIOf3Data?.mainDetails?.setName?.slice(0, data?.isSetIOf3Data?.mpDimentions?.length)?.map((resData, index) => {
                  const sizeFiled = data?.isSetIOf3Data?.mpDimentions?.[index]
                  const sizeUnit = data?.isSetIOf3Data?.mpDimentions?.[0]?.Unit?.value || ''
                  const specification = {
                    length: sizeFiled?.L || '',
                    width: sizeFiled?.W || '',
                    height: sizeFiled?.H || '',
                  }
                  const finalSize = convertDimentions(specification, sizeUnit, mpUnit, "")

                  return <>
                    <div className="d-flex justify-content-between">
                      <div>{resData}</div>
                      <div>{sizeFiled && finalSize ? `${finalSize} ${mpUnit.toUpperCase()}` : '-'}</div>
                    </div>
                    <div className='border-bottom mb-4'></div>
                  </>
                })}
              </div>
            </>

            }
            {/* Multiple Record data */}
            {multipleData?.map((multi, index) => {
              return <div
                key={`${multi.key}-${index}`}
                className="card pb-0 pt-3 ps-3 pe-3 pb-0 mt-3"
                style={{ marginLeft: 5, borderRadius: '3%' }}
              >
                <div className="title d-flex flex-row justify-content-between">
                  <h6 className="font-weight-semibold d-flex w-100 m-0 mb-2">
                    {multi.key}
                  </h6>
                </div>
                <div className='border-bottom mb-4'></div>
                {data?.isSetIOf3Data?.mainDetails?.setName?.slice(0, multi?.value?.length)?.map((data, index) => {
                  const sizeFiled = multi?.value?.[index]
                  return <>
                    <div className="d-flex justify-content-between">
                      <div>{data}</div>
                      <div>{sizeFiled || '-'}</div>
                    </div>
                    <div className='border-bottom mb-4'></div>
                  </>
                })}
              </div>
            })}
            {/* Single product detail */}
            {singleData?.length && <div className="card pb-0 pt-3 ps-3 pe-3 pb-0 mt-3 mb-4" style={{ marginLeft: 5, borderRadius: '3%' }}>
              <h6 className="font-weight-semibold d-flex w-100 m-0 mb-2">
                Product Details
              </h6>
              <div className='border-bottom mb-4'></div>
              {singleData.map((single, index) => {
                return <div key={`${single?.key}-${index}`}>
                  <div className="d-flex justify-content-between">
                    <div style={{ width: '40%' }} className="d-flex justify-content-start description">{single?.key}</div>
                    <div style={{ width: '60%' }} className="d-flex justify-content-end description">{single?.value}</div>
                  </div>
                  <div className='border-bottom mb-4'></div>
                </div>
              })}
            </div>
            }
          </div>
            :
            <div>
              <div className="align-items-center d-flex product-details-pricewrap">
                {companyMeta?.showPrice && price?.priceLabel && price?.priceValue ? (
                  <p className="product-details-price">{`${price?.priceLabel}${price?.priceValue}`}</p>
                ) : null}
                {companyMeta?.showPrice && price?.priceLabel && price?.priceValue && allCurrency.length > 1 && <Form.Select
                  aria-label="Default select example"
                  className="product-details-currancy"
                  onChange={handleCurrencyChange}
                >
                  {allCurrency?.map((currency) => {
                    return (<option value={currency.name}>{currency.name}</option>)
                  })}
                </Form.Select>}
              </div>
              <div className="product-imagesm product-images-wrap d-sm-none ">
                {/* <div className="product-imagesm product-images-wrap d-lg-none"> */}
                <ProductGallary
                  images={[
                    ...[{ image: data?.image, thumb: data?.thumb }],
                    ...data?.angleImages,
                  ]}
                  imageDetailObject={data}
                />
                {/* <div className="product-imagesm-wrap">
              <img src={p1} alt="p1" className="w-100" />
            </div>
            <div className="product-imagesm-button">
              <button className="product-imagesm-button-icon">
                <Expand />
              </button>
            </div> */}
              </div>
              {data?.specification && (data?.specification?.length || data?.specification?.width || data?.specification?.height) ? (<div className="dimensions">
                <div className="dimensions-lable">
                  <span>DIMENSIONS</span>
                </div>
                <Tabs defaultActiveKey="IN" className="mb-3" transition={false}>
                  {converter_inch_cm(data?.specification).map((val, i) => {
                    return (
                      <Tab
                        key={i}
                        eventKey={val.unit === "inches" ? "IN" : "CM"}
                        title={val.unit === "inches" ? "IN" : "CM"}
                      >
                        <div className="dimensions-input">{`${val.length} L X ${val.width} W X ${val.height} H ${val.unit === "inches" ? "IN" : "CM"}`}</div>
                      </Tab>
                    );
                  })}
                </Tabs>
              </div>
              ) : null}
              <div className="details">
                {(data?.materials || data?.finishes || data?.packageDimension || data?.cbm || (data?.ip && data?.mp)) && <div className="details-title">PRODUCT DETAILS</div>}
                {data?.materials ? (
                  <div className="d-flex justify-content-between details-list">
                    <span className="details-list-title">Material</span>
                    <span className="details-list-value">{data?.materials}</span>
                  </div>
                ) : null}
                {data?.finishes ? (
                  <div className="d-flex justify-content-between details-list">
                    <span className="details-list-title">Finish</span>
                    <span className="details-list-value">{data?.finishes}</span>
                  </div>
                ) : null}
                {companyMeta?.showPackageDimension && data?.packageDimension && (data?.packageDimension?.length || data?.packageDimension?.width || data?.packageDimension?.height) ? (
                  <div className="d-flex justify-content-between align-items-center details-list">
                    <span className="details-list-title">Carton Dimension</span>
                    <span className="details-list-value tabcontainerpackagedimension">
                      {companyMeta?.showPackageDimension && data?.packageDimension ? (
                        <>
                          <Tab.Container
                            id="my-tabs"
                            defaultActiveKey="inches"
                            transition={false}
                          >
                            <Tab.Content className="tabcontent">
                              {converter_inch_cm1(data?.packageDimension).map(
                                (val) => {
                                  return (
                                    <Tab.Pane key={val.unit} eventKey={val.unit}>
                                      <p className="white-space">{`${val.length} L X ${val.width} W X ${val.height} H ${val.unit === "inches" ? "IN" : "CM"}`}</p>
                                      {/* <p>{`${val.length} L X ${val.width} W X ${val.height} H ${val.unit === "inches" ? "IN" : "CM"}`}</p> */}
                                    </Tab.Pane>
                                  );
                                }
                              )}
                            </Tab.Content>
                            <Nav
                              variant="pills"
                              className="flex-row dimensions tabvalue"
                            >
                              {converter_inch_cm1(data?.packageDimension).map(
                                (val) => {
                                  return (
                                    <Nav.Item className="white-space" key={val.unit}>
                                      <Nav.Link eventKey={val.unit} onClick={() => handleTabSelect(val.unit)}  >
                                        {val.unit === "inches" ? "IN" : "CM"}
                                      </Nav.Link>
                                    </Nav.Item>
                                  );
                                }
                              )}
                            </Nav>
                          </Tab.Container>
                        </>
                      ) : null}
                    </span>
                  </div>
                ) : null}
                {data?.cbm ? (
                  <div className="d-flex justify-content-between details-list">
                    <span className="details-list-title">{titleAsPerUnit === "inches" ? "CFT / CARTON" : "CBM / CARTON"}</span>
                    <span className="details-list-value">{titleAsPerUnit === "inches" ? (data?.cbm * 35.31).toFixed(2) : data?.cbm}</span>
                  </div>
                ) : null}
                {companyMeta?.showIpMp && data?.ip && data?.mp ? (
                  <div className="d-flex justify-content-between details-list">
                    <span className="details-list-title">IP/MP</span>
                    <span className="details-list-value">{`${data?.ip}/${data?.mp} PCS`}</span>
                  </div>
                ) : null}
              </div>
              {data?.description && <div className="details">
                {data?.description ? (
                  <>
                    <div className="details-title">DESCRIPTION</div>
                    <div dangerouslySetInnerHTML={{ __html: data?.description }}></div>              </>
                ) : null}
                {/* <div className="d-flex justify-content-between details-list">
              <span className="details-list-title">Material</span>
              <span className="details-list-value">Iron</span>
            </div>
            <div className="d-flex justify-content-between details-list">
              <span className="details-list-title">Finish</span>
              <span className="details-list-value">Rustic</span>
            </div>
            <div className="d-flex justify-content-between details-list">
              <span className="details-list-title">Carton Dimension</span>
              <span className="details-list-value">59 L X 35 W X 49 H CM</span>
            </div>
            {data?.cbm?<div className="d-flex justify-content-between details-list">
              <span className="details-list-title">CBM / PC</span>
              <span className="details-list-value">{data?.cbm}</span>
            </div>:null}
            {data?.ip && data?.mp ? <div className="d-flex justify-content-between details-list">
              <span className="details-list-title">IP/MP</span>
              <span className="details-list-value">{`${data?.ip}/${data?.mp} PCS`}</span>
            </div>:null} */}
              </div>}
            </div>
          }
        </div>
        {companyMeta?.showEnquireNowBtn && <div className="enquire">
          <div className="enquire-button enquire-fixed">
            <button className="btn-black w-100" onClick={handleAddClick}>
              Enquire Now
            </button>
          </div>
        </div>}
      </div>
      <OrderEnquiry data={data} show={addModalOpen} onHide={handleClose} />
    </>
  );
};

export default ProductDetails;
