import React from "react";
import ProductGalleryImages from "../components/ProductGalleryImages";
import ProductGalleryDetails from "../components/ProductGalleryDetails";

const ProductItemPage = () => {
  return (
    <>
      <div className="product">
        <ProductGalleryImages />
        <ProductGalleryDetails />
      </div>
    </>
  );
};

export default ProductItemPage;
