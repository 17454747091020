import React from 'react'
import Poly9Viewer from '../components/modalViewer/Poly9Viewer';
import GoogleModalViewer from '../components/modalViewer/GoogleModalViewer';

const Poly9ViewerDecider = ({ itemProduct, companyDetails, companyMeta }) => {

  return (
    <>
      {/* FOR GOOGLE MODAL VIEWER */}
      {itemProduct?.shareViewer === "google" &&
        <GoogleModalViewer itemProduct={itemProduct} />
      }

      {/* FOR SKETCHFAB MODAL VIEWER */}
      {itemProduct?.shareViewer === "sketchfab" && <h3>Viewer not available</h3>}

      {/* FOR POLY9 MODAL VIEWER */}
      {itemProduct?.shareViewer === "poly9" &&
        <Poly9Viewer
          itemProduct={itemProduct}
          modelUrl={`${itemProduct?.gltfUrl || itemProduct?.fbxUrl || itemProduct?.objUrl}`}
          companyDetails={companyDetails}
          companyMeta={companyMeta}
        />
      }
      {itemProduct?.shareViewer === null &&
        <Poly9Viewer
          itemProduct={itemProduct}
          modelUrl={`${itemProduct?.gltfUrl || itemProduct?.fbxUrl || itemProduct?.objUrl}`}
        />
      }
    </>
  )
}

export default Poly9ViewerDecider