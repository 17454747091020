import { Storage } from "aws-amplify";
import { APP_BUCKET, APP_BUCKET_REGION, COGNITO_REGION, SUPPLIER_BUCKET, identityPoolId, region, userPoolId, userPoolWebClientId } from "./config";

export const awsExports = {

  Auth: {
    identityPoolId: identityPoolId,
    region: region,
    userPoolId: userPoolId,
    userPoolWebClientId: userPoolWebClientId,
  },

  Storage: {
    AWSS3: {
      bucket: APP_BUCKET,
      region: APP_BUCKET_REGION,
      level: 'public'
    },
  },
  SupplierStorage: {
    AWSS3: {
      bucket: SUPPLIER_BUCKET,
      region: COGNITO_REGION,
    },
    level: 'public'
  }
}
  ;

export const SetS3Config = (bucket, level) => {
  Storage.configure({
    bucket: APP_BUCKET,
    level: level,
    region: APP_BUCKET_REGION,
    identityPoolId: identityPoolId
  });
}