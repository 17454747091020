import React from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import p1 from "../assets/images/products/p1.png";
import { ReactComponent as Close } from "../assets/images/icons/close.svg";

const AddSelection = (props) => {
  const { show, onHide } = props;

  return (
    <>
      <Modal onHide={onHide} show={show} className="add-modal">
        <Modal.Header>
          <span className="m-auto">Add to Selection</span>
          <span className="modal-close" onClick={onHide}>
            <Close />
          </span>
        </Modal.Header>
        <Modal.Body>
          <div className="add-modal-product">
            <img src={p1} alt="p1" />
            <div className="add-modal-product-details w-100">
              <div className="add-modal-product-details-title">
                SET OF 3 CANDLE STAND
              </div>
              <div className="form">
                <Form>
                  <Form.Label>Notes</Form.Label>
                  <FloatingLabel controlId="floatingTextarea">
                    <Form.Control
                      as="textarea"
                      placeholder="Please write any question regards to Set of 3 Candle Stand product..."
                      rows={8}
                    />
                  </FloatingLabel>
                </Form>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center gap-3">
            <Button variant="primary" type="submit" className="btn-black">
              Submit
            </Button>
            <Button
              variant="primary"
              type="submit"
              className="btn-gray"
              onClick={onHide}
            >
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddSelection;
