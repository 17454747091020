import { CLOUD_FRONT_URL } from "../config";
import "../assets/css/pages/folder.scss"

const SelectionsProducts = ({ cartProduct, removeProductFromCart, editNotes }) => {
  return (
    <>
      <div className="selections-body">
        {
          cartProduct?.map((item) => (
            <div className="selections-product">
              <div className="align-items-center d-flex">
                <img src={`${CLOUD_FRONT_URL}${item?.thumb}`} alt={item.name} title={item.name} />
                <div className="selections-product-details">
                  <div className="selections-product-title">{item.name}</div>
                  <p className="selections-product-comment">{item.notes}</p>
                  <div className="selections-product-edit" onClick={() => editNotes(item)}>Edit Comment</div>
                </div>
              </div>
              <div onClick={() => removeProductFromCart(item)} style={{ cursor: 'pointer' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                  <g id="delete" transform="translate(-1064 -333)">
                    <path id="Path_1336" data-name="Path 1336" d="M14,0A14,14,0,1,1,0,14,14,14,0,0,1,14,0Z" transform="translate(1064 333)" fill="red" opacity="0.07" />
                    <g id="trash" transform="translate(1072.908 341.273)">
                      <path id="Path_335" data-name="Path 335" d="M11.572,2.015H10.011A2.524,2.524,0,0,0,7.542,0H6.534A2.524,2.524,0,0,0,4.066,2.015H2.5a.5.5,0,1,0,0,1.008h.5v6.55a2.522,2.522,0,0,0,2.519,2.519H8.55a2.522,2.522,0,0,0,2.519-2.519V3.023h.5a.5.5,0,0,0,0-1.008ZM6.534,1.008H7.542A1.514,1.514,0,0,1,8.967,2.015H5.109A1.514,1.514,0,0,1,6.534,1.008Zm3.527,8.565A1.511,1.511,0,0,1,8.55,11.084H5.527A1.511,1.511,0,0,1,4.015,9.572V3.023h6.046Z" transform="translate(-1.999 0)" fill="#fc2424" />
                      <path id="Path_336" data-name="Path 336" d="M9.5,14.031a.5.5,0,0,0,.5-.5V10.5A.5.5,0,0,0,9,10.5v3.023A.5.5,0,0,0,9.5,14.031Z" transform="translate(-5.473 -4.962)" fill="#fc2424" />
                      <path id="Path_337" data-name="Path 337" d="M13.5,14.031a.5.5,0,0,0,.5-.5V10.5A.5.5,0,0,0,13,10.5v3.023A.5.5,0,0,0,13.5,14.031Z" transform="translate(-7.457 -4.962)" fill="#fc2424" />
                    </g>
                  </g>
                </svg>
              </div>
            </div>
          ))
        }
      </div >
    </>
  );
};

export default SelectionsProducts;
