export const domains = {
  "gp.goldenpankh.com": {
    companyId: "5dfdd4534deb7fb955296d21",
    title: "Golden Pankh Export Import",
    description: "Golden Pankh Export Import started with a team of dexterous craftsmen and creative designers and transcended the marvelous work of art across the globe.",
    appleTouchIcon: "https://www.goldenpankh.in/assets/images/icons/favicon.ico",
    icon32: "https://www.goldenpankh.in/assets/images/icons/favicon-32x32.png",
    icon16: "https://www.goldenpankh.in/assets/images/icons/favicon-16x16.png",
    maskIcon: "https://www.goldenpankh.in/assets/images/icons/safari-pinned-tab.svg",
    shortcutIcon: "https://www.goldenpankh.in/assets/images/icons/apple-icon-180x180.png",
  },
  "anuraj.lanover.com": {
    companyId: "5d9dd1be2c482a1dbcaabf22",
    title: "Anuraj Exports",
    description: "We produce millions of pieces of wide variety of products with sense of responsibility simple case of a machine is causing a defect, left unchecked has a impact on environment (scrap rate) and, value (cost). Our strengths are automating, inspecting, and tracing products from start to finish.",
    appleTouchIcon: "https://www.anurajexports.com/assets/images/icons/appleTouchIcon.png",
    icon32: "https://www.anurajexports.com/assets/images/icons/favicon-32x32.png",
    icon16: "https://www.anurajexports.com/assets/images/icons/favicon-16x16.png",
    maskIcon: "https://www.anurajexports.com/assets/images/icons/safari-pinned-tab.svg",
    shortcutIcon: "https://www.anurajexports.com/assets/images/icons/favicon.ico",
  },
  "p9brandedshare.netlify.app": {
    companyId: "5d9dd1be2c482a1dbcaabf22",
    title: "Anuraj Exports",
    description: "We produce millions of pieces of wide variety of products with sense of responsibility simple case of a machine is causing a defect, left unchecked has a impact on environment (scrap rate) and, value (cost). Our strengths are automating, inspecting, and tracing products from start to finish.",
    appleTouchIcon: "https://www.anurajexports.com/assets/images/icons/appleTouchIcon.png",
    icon32: "https://www.anurajexports.com/assets/images/icons/favicon-32x32.png",
    icon16: "https://www.anurajexports.com/assets/images/icons/favicon-16x16.png",
    maskIcon: "https://www.anurajexports.com/assets/images/icons/safari-pinned-tab.svg",
    shortcutIcon: "https://www.anurajexports.com/assets/images/icons/favicon.ico",
  },
  'localhost:3000': {
    companyId: "5f98f388164cb394fc0a7695",
    title: "Golden Pankh Export Import",
    description: "Golden Pankh Export Import started with a team of dexterous craftsmen and creative designers and transcended the marvelous work of art across the globe.",
    appleTouchIcon: "https://www.goldenpankh.in/assets/images/icons/favicon.ico",
    icon32: "https://www.goldenpankh.in/assets/images/icons/favicon-32x32.png",
    icon16: "https://www.goldenpankh.in/assets/images/icons/favicon-16x16.png",
    maskIcon: "https://www.goldenpankh.in/assets/images/icons/safari-pinned-tab.svg",
    shortcutIcon: "https://www.goldenpankh.in/assets/images/icons/apple-icon-180x180.png",
  },
  'localhost:3001': {
    companyId: "5dfdd4534deb7fb955296d21",
    title: "Golden Pankh Export Import",
    description: "Golden Pankh Export Import started with a team of dexterous craftsmen and creative designers and transcended the marvelous work of art across the globe.",
    appleTouchIcon: "https://www.goldenpankh.in/assets/images/icons/favicon.ico",
    icon32: "https://www.goldenpankh.in/assets/images/icons/favicon-32x32.png",
    icon16: "https://www.goldenpankh.in/assets/images/icons/favicon-16x16.png",
    maskIcon: "https://www.goldenpankh.in/assets/images/icons/safari-pinned-tab.svg",
    shortcutIcon: "https://www.goldenpankh.in/assets/images/icons/apple-icon-180x180.png",
  }
}