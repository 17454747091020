import React, { useState, useEffect } from 'react';
import 'react-image-lightbox/style.css';
import Lightbox from 'react-image-lightbox';

const CustomLightBox = (props) => {

  const {
    photoIndex, isOpen, images, enableZoom = false
  } = props;

  const [currentSrc, setCurrentSrc] = useState(images[photoIndex]);
  let [index, setIndex] = useState(0);

  useEffect(() => {
    if (props.isOpen) {
      setCurrentSrc(images[photoIndex]);
      setIndex(0);
    }
    // eslint-disable-next-line
  }, [isOpen, photoIndex, images]);

  useEffect(() => {
    if (index > 0) {
      setCurrentSrc(images[photoIndex]);
    }
    // eslint-disable-next-line
  }, [index]);


  return (
    <div>
      {isOpen && (
        <Lightbox
          mainSrc={currentSrc}
          nextSrc={images?.length > 1 && images[(photoIndex + 1) % images.length] ? images[(photoIndex + 1) % images.length] : null}
          prevSrc={images?.length > 1 && images[(photoIndex + images.length - 1) % images.length] ? images[(photoIndex + images.length - 1) % images.length] : null}
          onCloseRequest={() => props.closeLightBox()}
          enableZoom={enableZoom}
          onMovePrevRequest={() => props?.setPhotoIndex((photoIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() => props?.setPhotoIndex((photoIndex + 1) % images.length)}
          onImageLoadError={(imageSrc, srcType, errorEvent) => {
            setIndex(index + 1);
          }}
        />
      )}
    </div>
  );
}

export default CustomLightBox;
