import React, { useEffect } from 'react'
import { getProductJSON } from "./getProductJSON"

const Poly9Viewer = ({ itemProduct, modelUrl, companyDetails, companyMeta }) => {

  useEffect(() => {

    const initialStyleJson = getProductJSON({
      product: itemProduct,
      companyMetaDetails: companyMeta,
      companyDetails: companyDetails,
    })

    const loadPoly9Viewer = () => {
      const t = 'https:' === document.location.protocol;
      const o = document.createElement('script');
      const p = (t ? 'https:' : 'http:') + '//cloud-viewer.poly9.ai/Poly9Viewer.js';
      o.type = 'text/javascript';
      o.async = true;
      o.src = p;

      const n = document.getElementsByTagName('script')[0];
      n.parentNode.insertBefore(o, n);
      o.onload = initializePoly9Viewer;
    };

    const initializePoly9Viewer = () => {
      window.poly9Viewer = new window.Poly9Viewer({
        iframeID: 'p9-viewer',
        key: 'F898BAE9FFCED68C',
        config: {
          fullScreenControl: true,
          rulerControl: true,
          shadowEffect: false,
          dimension: 'cm',
          loaderColor: 'ff000',
        },
      });

      window.poly9Viewer.onReady = () => {
        window.poly9Viewer.setStyle(initialStyleJson);
      };

      window.poly9Viewer.init();
    };

    loadPoly9Viewer();
    // eslint-disable-next-line
  }, []);


  return (
    <iframe
      id="p9-viewer"
      title={itemProduct?.name}
      allow="fullscreen"
      allowFullScreen
      style={{
        position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '75%', height: '70vh', margin: 'auto', border: 0
      }}
      src=""
    />
  )
}

export default Poly9Viewer