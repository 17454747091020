import React, { useState, useEffect, useRef } from 'react'
import { APP_BUCKET, CLOUD_FRONT_URL } from '../config';
import { ReactComponent as Close } from "../assets/images/icons/close.svg";
import { Link, useParams } from 'react-router-dom';
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "../assets/css/pages/folder.scss"
import { Button, Nav } from 'react-bootstrap';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { Col, Input, Label, Row } from 'reactstrap';
import { SpinViewer } from '../components/modalViewer/SpinnerViewer';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import CustomLightBox from '../components/CustomLightBox';
import DropzoneComponent from 'react-dropzone-component';
import { acceptanceImageFilesExtension, customProgressCallback, validateFileSize, validateFileType } from '../utils/utils';
import uuid4 from 'uuid4'
import { toast } from 'react-toastify';
import { SetS3Config } from '../aws_exports';
import { useMutation, useQuery } from '@apollo/client';
import { GET_IMAGE_STYLE_DETAIL_FOR_SELECTED_IMAGE_STYLE, UPDATE_IMAGE } from './shareFolderQuery/query';
import { Storage } from 'aws-amplify';

// ** MUI Imports *** //
import TimelineDot from '@mui/lab/TimelineDot'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import MuiTimeline from '@mui/lab/Timeline'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import FileDocumentOutline from 'mdi-material-ui/FileDocumentOutline'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import FilterIcon from '@mui/icons-material/Filter';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import moment from 'moment/moment';
import { round } from 'lodash';


const FolderImage = ({ itemProduct, nextHandler, companyMeta, selectItem, toggle, notes, handleNotesChangeInput, setNotes }) => {
  const productSettings = companyMeta?.getCompanyMeta?.generalSettings?.additionalImageFieldSettings;

  const [titleAsPerUnit, setTitleAsPerUnit] = useState("inches");
  const [spin360URL, setSpin360URL] = useState(null);
  const [isLightBoxOpen, setIsLightBoxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [attachment, setAttachment] = useState([]);
  const [attachmentLoading, setAttachmentLoading] = useState(false);
  const [productCurrentUnit, setProductCurrentUnit] = useState('in')
  const [cartoonCurrentUnit, setCartoonCurrentUnit] = useState('in')
  const [multipleData, setMultipleData] = useState([])
  const [singleData, setSingleData] = useState([])
  const [showDimesion, setShowDimesion] = useState(false)
  const [ipUnit, setIpnit] = useState('in')
  const [mpUnit, setMpUnit] = useState('in')

  const { companyId, _id } = useParams();
  const dropzoneRef = useRef(null);

  useEffect(() => {
    if (itemProduct?.spin360Renders && itemProduct.spin360Renders.length > 0) {
      setSpin360URL(itemProduct?.spin360Renders?.[0]?.detailView)
    }
    // eslint-disable-next-line
  }, [itemProduct?.spin360Renders])

  useEffect(() => {

    if (!itemProduct?.spin360Renders?.length) {
      setSpin360URL(null)
    }
    // eslint-disable-next-line
  }, [!itemProduct?.spin360Renders?.length])

  const [mutateFunction] = useMutation(UPDATE_IMAGE);

  const { data: imageData } = useQuery(GET_IMAGE_STYLE_DETAIL_FOR_SELECTED_IMAGE_STYLE, {
    variables: {
      _id: itemProduct._id,
      companyId
    },
    fetchPolicy: "network-only"
  })

  useEffect(() => {
    if (imageData?.getImageStyleDetailForSelectedImageStyle) {
      let filterData = imageData?.getImageStyleDetailForSelectedImageStyle[0]?.FolderAttachment?.filter((data) => {
        return data?.folderId === _id
      })
      setAttachment(filterData)
      const ipDimensionShow = productSettings?.showIpMpDimension || false
      const ipMPShow = productSettings?.showIpMp || false

      const setOf3Data = itemProduct?.isSetIOf3Data
      let multipleDataTemp = [];
      let singleDataTemp = []
      const keyNameChange = {
        setName: 'Set Name',
        productName: 'Product Name',
        sku: 'SKU',
        price: 'Price',
        category: 'Category',
        material: 'Material',
        finish: 'Finish',
        masterPack: 'Master Pack',
        description: 'Description',
      }
      const ArrayKey = ['category', 'material', 'finish']
      if (setOf3Data) {
        for (const key in setOf3Data?.mainDetails) {
          const filterLenght = setOf3Data?.mainDetails[key]?.filter(data => data)
          if (filterLenght.length > 1) {
            if (!['setName', 'price', 'pricecurrency']?.includes(key)) {
              if (ArrayKey.includes(key)) {
                const joinData = setOf3Data?.mainDetails[key]?.map(mapData => mapData?.filter(data => data)?.map(data => data?.label)?.filter(data => data)?.join(', '))
                multipleDataTemp = [...multipleDataTemp, { key: keyNameChange[key] || key, value: joinData || '-' }]
              } else {
                multipleDataTemp = [...multipleDataTemp, { key: keyNameChange[key] || key, value: setOf3Data?.mainDetails[key] }]
              }
            }
          } else {
            if (!['setName', 'price', 'pricecurrency']?.includes(key)) {
              if (ArrayKey.includes(key)) {
                const data1 = setOf3Data?.mainDetails[key]?.[0]
                const joinData = data1 && typeof data1 !== 'string' ? data1?.filter(data => data)?.map(data => data?.label)?.filter(data => data) : []
                singleDataTemp = [...singleDataTemp, { key: keyNameChange[key] || key, value: joinData?.join(', ') || '-' }]
              } else {
                singleDataTemp = [...singleDataTemp, { key: keyNameChange[key] || key, value: setOf3Data?.mainDetails[key]?.join(', ') || '-' }]
              }
            }
          }
        }
        for (const key in setOf3Data?.attributeDetails) {
          const filterLenght = setOf3Data?.attributeDetails[key]?.filter(data => data)
          if (filterLenght.length > 1) {
            multipleDataTemp = [...multipleDataTemp, { key: key, value: setOf3Data?.attributeDetails[key] }]
          } else {
            singleDataTemp = [...singleDataTemp, { key: key, value: setOf3Data?.attributeDetails[key]?.join(', ') || '-' }]
          }
        }
        setProductCurrentUnit((prevData) => prevData || setOf3Data?.productDimentions?.[0]?.Unit || 'in')
        setCartoonCurrentUnit((prevData) => prevData || setOf3Data?.cartoonDimentions?.[0]?.Unit || 'in')
        setIpnit((prevData) => prevData || setOf3Data?.ipDimentions?.[0]?.Unit || 'in')
        setMpUnit((prevData) => prevData || setOf3Data?.mpDimentions?.[0]?.Unit || 'in')
      }
      if (!ipMPShow) {
        singleDataTemp = singleDataTemp?.filter(data => !["Inner Pack", "Master Pack"].includes(data.key))
      }
      setShowDimesion(ipDimensionShow)
      setSingleData(singleDataTemp)
      setMultipleData(multipleDataTemp)
    }

    // eslint-disable-next-line
  }, [imageData?.getImageStyleDetailForSelectedImageStyle])


  const converter_inch_cm = (dimension) => {
    const { unit, width, height, length } = dimension;
    let dimensions = [dimension];
    const convertToCm = (value) => {
      return Math.round(value / 0.3937);
    };

    const convertToInch = (value) => {
      return Math.round(value * 0.3937);
    };

    if (unit === "cm") {
      // convert to inches
      dimensions?.push({
        unit: "inches",
        width: convertToInch(width),
        height: convertToInch(height),
        length: convertToInch(length),
      });
    } else if (unit === "inches") {

      // convert to cm
      dimensions?.push({
        unit: "cm",
        width: convertToCm(width),
        height: convertToCm(height),
        length: convertToCm(length),
      });
    }

    return dimensions;
  };

  const converter_inch_cm1 = (dimension) => {
    const { unit, width, height, length } = dimension;
    let dimensions = [dimension];
    const convertToCm = (value) => {
      let toFixVal = value / 0.3937
      let decimal = +(toFixVal).toString().split(".")[0]
      let point = +(toFixVal).toString().split(".")[1][0]
      toFixVal = point === 5 ? +(decimal + "." + point) : Math.round(toFixVal)
      return toFixVal
    };

    const convertToInch = (value) => {
      let toFixVal = (value * 0.3937)
      let decimal = +(toFixVal).toString().split(".")[0]
      let point = +(toFixVal).toString().split(".")[1][0]
      toFixVal = point === 5 ? +(decimal + "." + point) : Math.round(toFixVal)
      return toFixVal;
    };

    if (unit === "cm") {
      // convert to inches
      dimensions?.push({
        unit: "inches",
        width: convertToInch(width),
        height: convertToInch(height),
        length: convertToInch(length),
      });
    } else if (unit === "inches") {

      // convert to cm
      dimensions?.push({
        unit: "cm",
        width: convertToCm(width),
        height: convertToCm(height),
        length: convertToCm(length),
      });
    }

    return dimensions;
  };

  const handleTabSelect = (val) => {
    setTitleAsPerUnit(val)
  }

  const openLightBox = (photoIndex) => {
    setIsLightBoxOpen(true);
    setPhotoIndex(photoIndex);
  };

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1
  };

  var dropzoneConfig = {
    postUrl: 'no-url'
  };

  var djsConfig = { autoProcessQueue: false }

  const removeFile = (file) => {
    let index = selectedFiles.findIndex(it => it.uuid === file.uuid);
    if (index > -1) {
      let filePaths = [];
      filePaths?.push(selectedFiles?.[index].image)
      filePaths?.push(selectedFiles?.[index].thumb)
      filePaths?.push(selectedFiles?.[index].detailView)
      removeFile(Storage, filePaths);
      selectedFiles.splice(index, 1);
    }
    this.setState({ selectedFiles });
  };

  // HANDLE ATTACHMENT FUNCTION //
  const eventHandlers = {
    error: (data) => {
      toast.error("Can't upload file")
    },
    addedfile: (data) => {

      if (validateFileType(acceptanceImageFilesExtension(), data.type)) {
        if (validateFileSize(30, data.size)) {
          let type = data.type && data.type.length ? (data.type).split('/')[1] : data.name.split('.')[1];


          SetS3Config(APP_BUCKET, "public");
          if (data.type === "application/pdf") {
            Storage.put(`${companyId}/folder-attachment/${uuid4()}.${type}`, data, {
              contentType: "application/pdf",
              progressCallback: (progress) => customProgressCallback(progress, data)
            }).then(async (result) => {
              let name = data?.name?.slice(0, data.name.lastIndexOf("."));
              setSelectedFiles({
                file: result.key,
                name,
                type: data.type,
              })
            }).catch(err => {
              toast.error(err.message);
            });

          } else {
            Storage.put(`${companyId}/folder-attachment${uuid4()}.${type}`, data, {
              contentType: type,
              progressCallback: (progress) => customProgressCallback(progress, data)
            }).then(async (result) => {
              let name = data?.name?.slice(0, data.name.lastIndexOf("."));
              setSelectedFiles({
                file: result.key,
                name,
                type: data.type,
                base64: data
              })
            }).catch(err => {
              toast.error(err.message);
            });
          }
        }

      } else {
        toast.warn("Please upload .jpg , jpeg .png , .pdf");
      }
    },
    removedfile: (data) => {
      removeFile({ uuid: data.upload.uuid });
    }
  }

  const Timeline = styled(MuiTimeline)({
    paddingLeft: 0,
    paddingRight: 0,
    '& .MuiTimelineItem-root': {
      width: '100%',
      '&:before': {
        display: 'none'
      }
    }
  })

  const FileIcon = ({ fileType }) => {
    if (fileType === '.pdf') {
      return <PictureAsPdfIcon />
    } else if (fileType === '.jpg' || fileType === '.jpeg') {
      return <PhotoSizeSelectActualIcon />
    } else if (fileType === '.png') {
      return <FilterIcon />
    } else {
      return <FileDocumentOutline style={{ fontSize: '20px' }} />
    }
  }

  // ADD ATTACHMENT //
  const saveNotesAttachment = () => {
    const date = moment().format('Do MMM YYYY h:mm A');
    if (notes === "" && selectedFiles === null) {
      toast.warning("Please select attachments or add notes")
    } else {
      setAttachmentLoading(true)
      mutateFunction({
        variables: {
          companyId,
          _id: itemProduct?._id,
          name: itemProduct?.name,
          image: itemProduct?.image,
          thumb: itemProduct?.thumb,
          detailView: itemProduct?.detailView,
          FolderAttachment: {
            notes: notes,
            file: selectedFiles?.file,
            name: selectedFiles?.name,
            type: selectedFiles?.type,
            createdAt: date,
            folderId: _id,
          },
        }
      }).then(data => {
        setNotes("")
        setSelectedFiles(null)
        setAttachmentLoading(false)
        if (dropzoneRef?.current?.dropzone) {
          dropzoneRef.current.dropzone.files = [];
        }
      }).catch((error) => {
        toast.error(error.message);
        setAttachmentLoading(false)
      })
    }
  }

  // ATTACHMENT DOWNLOAD
  const handleDownload = async (downloadUrl, fileName) => {
    try {
      const response = await fetch(downloadUrl);
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      link.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      toast.error(error.message);
    }
  };
  const floorTheDimensionValue = (value) => {
    if (value.toString().includes('.5')) {
      return (Math.floor(value) + 0.5).toString();
    } else {
      return Math.round(value).toString();
    }
  }

  const generateOutput = (l, w, h) => {
    let output = "";
    if (l) {
      output += `${l}L`;
      if (w || h) {
        output += " X ";
      }
    }
    if (w) {
      output += `${w}W`;
      if (h) {
        output += " X ";
      }
    }
    if (h) {
      output += `${h}H`;
    }
    return output;
  }
  const convertDimentions = (specification, unit, newUnit) => {
    let l = specification["length"];
    let w = specification["width"];
    let h = specification["height"];
    if (l || w || h) {
      if (unit === newUnit) {
        // return `${l ? `${l} L` : ''} ${l && w ? " X " : ""} ${w ? `${w} W` : ''} ${h && w ? " X " : ""}${h ? `${h} H` : ''}`.trim();
        return generateOutput(l, w, h)
      } else {
        if (["cm", 'CM', 'CMS'].includes(unit) && ['inches', 'IN', 'INCH', 'in'].includes(newUnit)) {
          const lValue = l ? floorTheDimensionValue(round((parseFloat(l) / 2.54), 2)) : ''
          const wValue = w ? floorTheDimensionValue(round((parseFloat(w) / 2.54), 2)) : ''
          const hValue = h ? floorTheDimensionValue(round((parseFloat(h) / 2.54), 2)) : ''
          return generateOutput(lValue, wValue, hValue)
        } else if (['inches', 'IN', 'INCH', 'in'].includes(unit) && ["cm", 'CM', 'CMS'].includes(newUnit)) {
          const lValue = l ? floorTheDimensionValue(round((parseFloat(l) * 2.54), 2)) : ''
          const wValue = w ? floorTheDimensionValue(round((parseFloat(w) * 2.54), 2)) : ''
          const hValue = h ? floorTheDimensionValue(round((parseFloat(h) * 2.54), 2)) : ''
          return generateOutput(lValue, wValue, hValue)
        } else {
          return generateOutput(l, w, h)
        }
      }
    }
  }
  // SORTING ATTACHMENTS DATA //
  const sortedAttachment = [...(attachment || [])].sort((a, b) => {
    const dateA = moment(a?.createdAt, "Do MMM YYYY h:mm A").toDate();
    const dateB = moment(b?.createdAt, "Do MMM YYYY h:mm A").toDate();
    return dateB - dateA;
  });




  return (
    <div>
      <div className='lg-hide'>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Link to="/">
            <img
              src={`${CLOUD_FRONT_URL}${itemProduct?.companyLogo}`}
              alt={"GPEI"}
              title={"GPEI"}
              className="product-details-logo logo"
            />
          </Link>
          <h6 className="product-details-tagline">
            {itemProduct?.companyName}
          </h6>
        </div>
          <button onClick={toggle} className='close-icon' style={{ top: 20, right: 20 }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 12 12">
              <path id="close" d="M17.428,5.986h0a.953.953,0,0,0-1.348,0l-4.373,4.373L7.335,5.986a.953.953,0,0,0-1.348,0h0a.953.953,0,0,0,0,1.348l4.373,4.373L5.986,16.08a.953.953,0,0,0,0,1.348h0a.953.953,0,0,0,1.348,0l4.373-4.373,4.373,4.373a.953.953,0,0,0,1.348,0h0a.953.953,0,0,0,0-1.348l-4.373-4.373,4.373-4.373A.953.953,0,0,0,17.428,5.986Z" transform="translate(-5.707 -5.707)" fill="#202027" />
            </svg>
          </button>
      </div>
      <Row className='align-content-center'>
        <Col lg={7} md={6} sm={12} style={{ paddingTop: '35px' }}>
          {/* PRODUCT IMAGE */}
          <div className="folder-product-images">

            <button
              className="folder-product-left-nav"
              onClick={() => nextHandler(itemProduct?._id, -1)}
            >
              <BsChevronLeft /> Prev
            </button>

            <div className="folder-product-modal">

              {
                spin360URL ? <div
                  className={"w-100 position-relative"}
                  style={{ height: `calc(100% - 64px)`, zIndex: 0 }}>
                  <SpinViewer loadS3bucket360={spin360URL} />
                </div> : <img src={`${CLOUD_FRONT_URL}${itemProduct?.thumb}`} alt={itemProduct?.name} />
              }
            </div>

            <button
              className="folder-product-right-nav"
              onClick={() => nextHandler(itemProduct?._id, 1)}
            >
             Next <BsChevronRight />
            </button>

          </div>

          {/* BOTTOM ANGLE IMAGES */}
          {itemProduct?.angleImages?.length <= 2 ?
            <Row className='justify-content-center'>
              {itemProduct?.angleImages?.map((img, index) => (
                <div
                  className={`d-flex align-items-center justify-content-center angle-card`}
                  onClick={e => {
                    e.preventDefault()
                    openLightBox(index);
                  }}
                >
                  <img
                    src={`${CLOUD_FRONT_URL}${img?.thumb}?thumb=1`}
                    alt={img?._id}
                    className="angle-card-img"
                  />
                </div>
              ))}
            </Row>
            :
            <Slider {...settings}>
              {itemProduct?.angleImages?.map((img, index) => {
                return <div
                  className={`d-flex align-items-center justify-content-center angle-card`}
                  onClick={e => {
                    e.preventDefault()
                    openLightBox(index);
                  }}
                >
                  <img
                    src={`${CLOUD_FRONT_URL}${img.thumb}?thumb=1`}
                    alt={img._id}
                    className="angle-card-img"
                  />
                </div>
              })}
            </Slider>

          }

          {
            itemProduct?.angleImages?.length > 0 ? <CustomLightBox
              isOpen={isLightBoxOpen}
              images={itemProduct?.angleImages.map(it => `${CLOUD_FRONT_URL}${it.detailView}`)}
              photoIndex={photoIndex}
              closeLightBox={() => setIsLightBoxOpen(!isLightBoxOpen)}
              setPhotoIndex={setPhotoIndex}
              enableZoom={true}
              hideDownload={true}
            /> : ""
          }


        </Col>
        <Col lg={5} md={6} sm={12}>
          {/* IMAGE DETAILS  */}
          {itemProduct?.isSetIOf3tem ?
            <div className="product-details folder-product-details">
              <div className="product-details-body">
                <div>
                  <div className="product-details-body-close">
                    <Close />

                  </div>
                  <div className="product-details-header sm-hide">
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Link to="/">
                        <img
                          src={`${CLOUD_FRONT_URL}${itemProduct?.companyLogo}`}
                          alt={"GPEI"}
                          title={"GPEI"}
                          className="product-details-logo logo"
                        />
                      </Link>
                      <h6 className="product-details-tagline">
                        {itemProduct?.companyName}
                      </h6>
                    </div>

                    <button onClick={toggle} className='close-icon'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 12 12">
                        <path id="close" d="M17.428,5.986h0a.953.953,0,0,0-1.348,0l-4.373,4.373L7.335,5.986a.953.953,0,0,0-1.348,0h0a.953.953,0,0,0,0,1.348l4.373,4.373L5.986,16.08a.953.953,0,0,0,0,1.348h0a.953.953,0,0,0,1.348,0l4.373-4.373,4.373,4.373a.953.953,0,0,0,1.348,0h0a.953.953,0,0,0,0-1.348l-4.373-4.373,4.373-4.373A.953.953,0,0,0,17.428,5.986Z" transform="translate(-5.707 -5.707)" fill="#202027" />
                      </svg>
                    </button>
                  </div>
                  <p className="product-details-sku">{itemProduct?.sku}</p>
                  <h1 className="product-details-title mb-4">{itemProduct?.name}</h1>
                  <div>
                    {/* <Row className="d-flex " style={{ marginLeft: 5, paddingBottom: 10 }}>
               {itemProduct?.isSetIOf3Data?.mainDetails?.setName?.map((mainData, index) => {
                 const value = itemProduct?.isSetIOf3Data?.mainDetails?.price[index]
                 return <Col xxl={4} xl={4} lg={4} md={12} sm={12} xs={12} xxs={12} className="mt-2 p-0">
                   <div style={{ padding: "10px 20px", borderRadius: 50, background: '#fdf0f7', marginRight: 10, display: 'flex' }}>
                     {mainData} <div className="pink-color ms-1">{value ? ` $${value}` : ''}</div>
                   </div>
                 </Col>
               })}
             </Row> */}

                    <div className='d-flex flex-wrap mb-3'>
                      {itemProduct?.isSetIOf3Data?.mainDetails?.setName?.map((data, index) => {
                        const value = itemProduct?.isSetIOf3Data?.mainDetails?.price[index]
                        const currency = itemProduct?.isSetIOf3Data?.mainDetails?.pricecurrency?.[0]?.value || '$'
                        return <div className="mt-2 pl-0">
                          <div style={{ padding: "10px 20px", borderRadius: 50, background: '#fdf0f7', marginRight: 10, display: 'inline-block' }}>
                            {data} <span className="pink-color ml-1">{value ? ` ${currency}${value}` : ''}</span>
                          </div>
                        </div>
                      })}
                    </div>

                    {/* Product Dimensions card */}
                    <div className="card pb-0 pt-3 ps-3 pe-3 pb-0" style={{ marginLeft: 5, borderRadius: '3%' }}>
                      <div className="title d-flex flex-row justify-content-between">
                        <h6 className="font-weight-semibold d-flex w-100 m-0 mb-2">
                          Product Dimensions
                        </h6>
                        <div className="select-custom d-flex mb-1" style={{ flexWrap: 'nowrap' }}>
                          <div
                            className={`me-2  cursor-pointer ${productCurrentUnit === 'in' ? 'active-dimension' : 'deActive-dimension'}`}
                            onClick={() => {
                              setProductCurrentUnit('in')
                            }}
                          >IN</div>
                          <div
                            className={`me-2  cursor-pointer ${productCurrentUnit === 'cm' ? 'active-dimension' : 'deActive-dimension'}`}
                            onClick={() => {
                              setProductCurrentUnit('cm')
                            }}>CM</div>
                        </div>
                      </div>
                      <div className='border-bottom mb-4'></div>
                      {itemProduct?.isSetIOf3Data?.mainDetails?.setName?.slice(0, itemProduct?.isSetIOf3Data?.productDimentions?.length)?.map((resData, index) => {
                        const sizeFiled = itemProduct?.isSetIOf3Data?.productDimentions?.[index]
                        const sizeUnit = itemProduct?.isSetIOf3Data?.productDimentions?.[0]?.Unit?.value || ''
                        const specification = {
                          length: sizeFiled?.L || '',
                          width: sizeFiled?.W || '',
                          height: sizeFiled?.H || '',
                        }
                        const finalSize = convertDimentions(specification, sizeUnit, productCurrentUnit, "")
                        return <>
                          <div className="d-flex justify-content-between">
                            <div>{resData}</div>
                            <div>{sizeFiled && finalSize ? `${finalSize} ${productCurrentUnit?.toUpperCase()}` : '-'}</div>
                          </div>
                          <div className='border-bottom mb-4'></div>
                        </>
                      })}
                    </div>
                    {/* Cartoon Dimensions card */}
                    <div className="card pb-0 pt-3 ps-3 pe-3 pb-0 mt-3" style={{ marginLeft: 5, borderRadius: '3%' }}>
                      <div className="title d-flex flex-row justify-content-between">
                        <h6 className="font-weight-semibold d-flex w-100 m-0 mb-2">
                          Cartoon Dimensions
                        </h6>
                        <div className="select-custom d-flex mb-1" style={{ flexWrap: 'nowrap' }}>
                          <div
                            className={`me-2  cursor-pointer ${cartoonCurrentUnit === 'in' ? 'active-dimension' : 'deActive-dimension'}`}
                            onClick={() => {
                              setCartoonCurrentUnit('in')
                            }}
                          >IN</div>
                          <div
                            className={`me-2  cursor-pointer ${cartoonCurrentUnit === 'cm' ? 'active-dimension' : 'deActive-dimension'}`}
                            onClick={() => {
                              setCartoonCurrentUnit('cm')
                            }}>CM</div>
                        </div>
                      </div>
                      <div className='border-bottom mb-4'></div>
                      {itemProduct?.isSetIOf3Data?.mainDetails?.setName?.slice(0, itemProduct?.isSetIOf3Data?.cartoonDimentions?.length)?.map((resData, index) => {
                        const sizeFiled = itemProduct?.isSetIOf3Data?.cartoonDimentions?.[index]
                        const sizeUnit = itemProduct?.isSetIOf3Data?.cartoonDimentions?.[0]?.Unit?.value || ''
                        const specification = {
                          length: sizeFiled?.L || '',
                          width: sizeFiled?.W || '',
                          height: sizeFiled?.H || '',
                        }

                        const finalSize = convertDimentions(specification, sizeUnit, cartoonCurrentUnit, "")

                        return <>
                          <div className="d-flex justify-content-between">
                            <div>{resData}</div>
                            <div>{sizeFiled && finalSize ? `${finalSize} ${cartoonCurrentUnit.toUpperCase()}` : '-'}</div>
                          </div>
                          <div className='border-bottom mb-4'></div>
                        </>
                      })}
                    </div>
                    {/* Ip Dimensions card */}
                    {showDimesion && <>
                      <div className="card pb-0 pt-3 ps-3 pe-3 pb-0 mt-3" style={{ marginLeft: 5, borderRadius: '3%' }}>
                        <div className="title d-flex flex-row justify-content-between">
                          <h6 className="font-weight-semibold d-flex w-100 m-0 mb-2">
                            IP Dimensions
                          </h6>
                          <div className="select-custom d-flex mb-1" style={{ flexWrap: 'nowrap' }}>
                            <div
                              className={`me-2  cursor-pointer ${ipUnit === 'in' ? 'active-dimension' : 'deActive-dimension'}`}
                              onClick={() => {
                                setIpnit('in')
                              }}
                            >IN</div>
                            <div
                              className={`me-2  cursor-pointer ${ipUnit === 'cm' ? 'active-dimension' : 'deActive-dimension'}`}
                              onClick={() => {
                                setIpnit('cm')
                              }}>CM</div>
                          </div>
                        </div>
                        <div className='border-bottom mb-4'></div>
                        {itemProduct?.isSetIOf3Data?.mainDetails?.setName?.slice(0, itemProduct?.isSetIOf3Data?.ipDimentions?.length)?.map((resData, index) => {
                          const sizeFiled = itemProduct?.isSetIOf3Data?.ipDimentions?.[index]
                          const sizeUnit = itemProduct?.isSetIOf3Data?.ipDimentions?.[0]?.Unit?.value || ''
                          const specification = {
                            length: sizeFiled?.L || '',
                            width: sizeFiled?.W || '',
                            height: sizeFiled?.H || '',
                          }
                          const finalSize = convertDimentions(specification, sizeUnit, ipUnit, "")

                          return <>
                            <div className="d-flex justify-content-between">
                              <div>{resData}</div>
                              <div>{sizeFiled && finalSize ? `${finalSize} ${ipUnit.toUpperCase()}` : '-'}</div>
                            </div>
                            <div className='border-bottom mb-4'></div>
                          </>
                        })}
                      </div>
                      <div className="card pb-0 pt-3 ps-3 pe-3 pb-0 mt-3" style={{ marginLeft: 5, borderRadius: '3%' }}>
                        <div className="title d-flex flex-row justify-content-between">
                          <h6 className="font-weight-semibold d-flex w-100 m-0 mb-2">
                            MP Dimensions
                          </h6>
                          <div className="select-custom d-flex mb-1" style={{ flexWrap: 'nowrap' }}>
                            <div
                              className={`me-2  cursor-pointer ${mpUnit === 'in' ? 'active-dimension' : 'deActive-dimension'}`}
                              onClick={() => {
                                setMpUnit('in')
                              }}
                            >IN</div>
                            <div
                              className={`me-2  cursor-pointer ${mpUnit === 'cm' ? 'active-dimension' : 'deActive-dimension'}`}
                              onClick={() => {
                                setMpUnit('cm')
                              }}>CM</div>
                          </div>
                        </div>
                        <div className='border-bottom mb-4'></div>
                        {itemProduct?.isSetIOf3Data?.mainDetails?.setName?.slice(0, itemProduct?.isSetIOf3Data?.mpDimentions?.length)?.map((resData, index) => {
                          const sizeFiled = itemProduct?.isSetIOf3Data?.mpDimentions?.[index]
                          const sizeUnit = itemProduct?.isSetIOf3Data?.mpDimentions?.[0]?.Unit?.value || ''
                          const specification = {
                            length: sizeFiled?.L || '',
                            width: sizeFiled?.W || '',
                            height: sizeFiled?.H || '',
                          }
                          const finalSize = convertDimentions(specification, sizeUnit, mpUnit, "")

                          return <>
                            <div className="d-flex justify-content-between">
                              <div>{resData}</div>
                              <div>{sizeFiled && finalSize ? `${finalSize} ${mpUnit.toUpperCase()}` : '-'}</div>
                            </div>
                            <div className='border-bottom mb-4'></div>
                          </>
                        })}
                      </div>
                    </>

                    }
                    {/* Multiple Record data */}
                    {multipleData?.map((multi, index) => {
                      return <div
                        key={`${multi.key}-${index}`}
                        className="card pb-0 pt-3 ps-3 pe-3 pb-0 mt-3"
                        style={{ marginLeft: 5, borderRadius: '3%' }}
                      >
                        <div className="title d-flex flex-row justify-content-between">
                          <h6 className="font-weight-semibold d-flex w-100 m-0 mb-2">
                            {multi.key}
                          </h6>
                        </div>
                        <div className='border-bottom mb-4'></div>
                        {itemProduct?.isSetIOf3Data?.mainDetails?.setName?.slice(0, multi?.value?.length)?.map((data, index) => {
                          const sizeFiled = multi?.value[index]
                          return <>
                            <div className="d-flex justify-content-between">
                              <div>{data}</div>
                              <div>{sizeFiled || '-'}</div>
                            </div>
                            <div className='border-bottom mb-4'></div>
                          </>
                        })}
                      </div>
                    })}
                    {/* Single product detail */}
                    {singleData?.length && <div className="card pb-0 pt-3 ps-3 pe-3 pb-0 mt-3 mb-4" style={{ marginLeft: 5, borderRadius: '3%' }}>
                      <h6 className="font-weight-semibold d-flex w-100 m-0 mb-2">
                        Product Details
                      </h6>
                      <div className='border-bottom mb-4'></div>
                      {singleData.map((single, index) => {
                        return <div key={`${single?.key}-${index}`}>
                          <div className="d-flex justify-content-between">
                            <div style={{ width: '40%' }} className="d-flex justify-content-start description">{single?.key}</div>
                            <div style={{ width: '60%' }} className="d-flex justify-content-end description">{single?.value}</div>
                          </div>
                          <div className='border-bottom mb-4'></div>
                        </div>
                      })}
                    </div>
                    }
                  </div>
                </div>
                {/* NOTES INPUT */}
                <div className='mb-3'>
                  <Label>
                    Notes & Attachment
                  </Label>
                  <Input
                    type="textarea"
                    placeholder="Notes"
                    rows={5}
                    onChange={(e) => handleNotesChangeInput(e)}
                    value={notes}
                  />
                </div>

                {/* FILE UPLOADER */}
                <Row className='mt-3 align-items-center'>
                  <Col sm={9} lg={9} md={9} className='attachment-drop-col'>
                    <div className='product-drop-zone'>
                      <div className='d-flex file-name-icon'>
                        <AttachFileIcon className='attachment-icon' />
                        <p className={selectedFiles?.name ? 'attach-file-name' : 'text-warp'}>{selectedFiles?.name ? selectedFiles?.name : "Attach File"}</p>
                      </div>
                      <DropzoneComponent
                        hint={'Please upload only .png .jpg .jpeg file'}
                        dropImageClass={"img-md"}
                        multiple={false}
                        eventHandlers={eventHandlers}
                        config={dropzoneConfig}
                        djsConfig={djsConfig}
                        acceptedFiles={"image/jpeg,image/jpg,image/png,application/pdf"}
                      />
                    </div></Col>

                  <Col className='d-flex justify-content-end attachment-save-btn-col' sm={3} lg={3} md={3}><Button className={attachmentLoading ? 'btn-success' : 'btn-black'} onClick={saveNotesAttachment}>{attachmentLoading ? "Processing..." : "Save"}</Button></Col>
                </Row>

                {/* TIMELINE WISE ATTACHMENT VIEW */}
                <Timeline>
                  {
                    sortedAttachment?.map((item) => {
                      var fileExtension = item?.type?.split('/')[1];

                      return <TimelineItem>
                        <Box sx={{ flexDirection: 'column', mt: 1 }} className="me-3">
                          <Typography>{moment(item?.createdAt, "Do MMM YYYY h:mm A").format("MMM DD YYYY")}</Typography>
                          <Typography variant='caption'>{moment(item?.createdAt, "Do MMM YYYY h:mm A").format("h:mm A")}</Typography>
                        </Box>
                        <TimelineSeparator >
                          <TimelineDot color='secondary' />
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                          <Box >
                            <div>
                              <p className='ms-1 file_name mb-1'>{item?.notes}</p>
                              {item?.file &&
                                <>
                                  <div className='d-flex mt-2'>
                                    <FileIcon fileType={fileExtension} />
                                    <div>
                                      <p onClick={() => handleDownload(CLOUD_FRONT_URL + item?.file, item?.file)} className='file_name mb-2 cursor-pointer' >{item?.name}</p>
                                      <p
                                        onClick={() => handleDownload(CLOUD_FRONT_URL + item?.file, item?.file)}
                                        style={{
                                          cursor: 'pointer'
                                        }}
                                        className='mb-0'
                                      >Download</p>
                                    </div>
                                  </div>
                                </>
                              }
                            </div>
                          </Box>
                        </TimelineContent>
                      </TimelineItem>
                    })
                  }
                </Timeline>
              </div>
              <div className="enquire">
                <div className="enquire-button">
                  <button className="btn-black w-100"
                    onClick={selectItem}
                  >
                    Select Item
                  </button>
                </div>
              </div>
            </div>

            :
            <div className="product-details folder-product-details">
              <div className="product-details-body">
                <div className="product-details-body-close">
                  <Close />

                </div>
                <div className="product-details-header sm-hide">
                  <div>
                    <Link to="/">
                      <img
                        src={`${CLOUD_FRONT_URL}${itemProduct?.companyLogo}`}
                        alt={"GPEI"}
                        title={"GPEI"}
                        className="product-details-logo logo"
                      />
                    </Link>
                    <h6 className="product-details-tagline">
                      {itemProduct?.companyName}
                    </h6>
                  </div>

                  <button onClick={toggle} className='close-icon'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 12 12">
                      <path id="close" d="M17.428,5.986h0a.953.953,0,0,0-1.348,0l-4.373,4.373L7.335,5.986a.953.953,0,0,0-1.348,0h0a.953.953,0,0,0,0,1.348l4.373,4.373L5.986,16.08a.953.953,0,0,0,0,1.348h0a.953.953,0,0,0,1.348,0l4.373-4.373,4.373,4.373a.953.953,0,0,0,1.348,0h0a.953.953,0,0,0,0-1.348l-4.373-4.373,4.373-4.373A.953.953,0,0,0,17.428,5.986Z" transform="translate(-5.707 -5.707)" fill="#202027" />
                    </svg>
                  </button>
                </div>
                <p className="product-details-sku">{itemProduct?.sku}</p>
                <h1 className="product-details-title mb-4">{itemProduct?.name}</h1>

                <div className="product-imagesm product-images-wrap d-sm-none">
                  {/* <div className="product-imagesm product-images-wrap d-lg-none"> */}
                  <div className="product-images-wrap">
                  </div>
                </div>
                {itemProduct?.specification && (itemProduct?.specification?.length || itemProduct?.specification?.width || itemProduct?.specification?.height) ? (
                  <div className="dimensions">
                    <div className="dimensions-lable">
                      <span>DIMENSIONS</span>
                    </div>
                    <Tabs defaultActiveKey="IN" className="mb-3" transition={false}>
                      {converter_inch_cm(itemProduct?.specification).map((val, i) => {
                        return (
                          <Tab
                            key={i}
                            eventKey={val.unit === "inches" ? "IN" : "CM"}
                            title={val.unit === "inches" ? "IN" : "CM"}
                          >
                            <div className="dimensions-input">{val.length ? `${val.length} L X ` : ''} {val?.width ? `${val?.width} W X ` : ''} {val?.height ? `${val?.height} H ` : ''} {val.unit === "inches" ? "IN" : "CM"} </div>
                          </Tab>
                        );
                      })}
                    </Tabs>
                  </div>
                ) : null}
                <div className="details">

                  {(itemProduct?.materials || itemProduct?.finishes || itemProduct?.packageDimension || itemProduct?.cbm || (itemProduct?.ip && itemProduct?.mp)) && <div className="details-title">PRODUCT DETAILS</div>}
                  {itemProduct?.materials ? (
                    <div className="d-flex justify-content-between details-list">
                      <span className="details-list-title">Material</span>
                      <span className="details-list-value">{itemProduct?.materials}</span>
                    </div>
                  ) : null}
                  {itemProduct?.finishes ? (
                    <div className="d-flex justify-content-between details-list">
                      <span className="details-list-title">Finish</span>
                      <span className="details-list-value">{itemProduct?.finishes}</span>
                    </div>
                  ) : null}
                  {productSettings?.showPackageDimension && itemProduct?.packageDimension && itemProduct?.packageDimension && (itemProduct?.packageDimension?.length || itemProduct?.packageDimension?.width || itemProduct?.packageDimension?.height) ? (
                    <div className="d-flex justify-content-between align-items-center details-list">
                      <span className="details-list-title">Carton Dimension</span>
                      <span className="details-list-value tabcontainerpackagedimension">
                        {productSettings?.showPackageDimension && itemProduct?.packageDimension ? (
                          <>
                            <Tab.Container
                              id="my-tabs"
                              defaultActiveKey="inches"
                              transition={false}
                            >
                              <Tab.Content className="tabcontent">
                                {converter_inch_cm1(itemProduct?.packageDimension).map(
                                  (val) => {
                                    return (
                                      <Tab.Pane key={val.unit} eventKey={val.unit}>
                                        <p className="white-space">{val.length ? `${val.length} L X ` : ''} {val?.width ? `${val?.width} W X ` : ''} {val?.height ? `${val?.height} H ` : ''} {val.unit === "inches" ? "IN" : "CM"} </p>
                                      </Tab.Pane>
                                    );
                                  }
                                )}
                              </Tab.Content>
                              <Nav
                                variant="pills"
                                className="flex-row dimensions tabvalue"
                              >
                                {converter_inch_cm1(itemProduct?.packageDimension).map(
                                  (val) => {
                                    return (
                                      <Nav.Item className="white-space" key={val.unit}>
                                        <Nav.Link eventKey={val.unit} onClick={() => handleTabSelect(val.unit)}  >
                                          {val.unit === "inches" ? "IN" : "CM"}
                                        </Nav.Link>
                                      </Nav.Item>
                                    );
                                  }
                                )}
                              </Nav>
                            </Tab.Container>
                          </>
                        ) : null}
                      </span>
                    </div>
                  ) : null}
                  {itemProduct?.cbm ? (
                    <div className="d-flex justify-content-between details-list">
                      <span className="details-list-title">{titleAsPerUnit === "inches" ? "CFT / CARTON" : "CBM / CARTON"}</span>
                      <span className="details-list-value">{titleAsPerUnit === "inches" ? (itemProduct?.cbm * 35.31).toFixed(2) : itemProduct?.cbm}</span>
                    </div>
                  ) : null}
                  {productSettings?.showIpMp && itemProduct?.ip && itemProduct?.mp ? (
                    <div className="d-flex justify-content-between details-list">
                      <span className="details-list-title">IP/MP</span>
                      <span className="details-list-value">{`${itemProduct?.ip}/${itemProduct?.mp} PCS`}</span>
                    </div>
                  ) : null}
                </div>

                {itemProduct?.description &&
                  <div className="details">
                    <div className="details-title">DESCRIPTION</div>
                    <div dangerouslySetInnerHTML={{ __html: itemProduct?.description }}></div>
                  </div>
                }

                {/* NOTES INPUT */}
                <div className='mb-3'>
                  <Label>
                    Notes & Attachment
                  </Label>
                  <Input
                    type="textarea"
                    placeholder="Notes"
                    rows={5}
                    onChange={(e) => handleNotesChangeInput(e)}
                    value={notes}
                  />
                </div>

                {/* FILE UPLOADER */}
                <Row className='mt-3 align-items-center'>
                  <Col sm={9} lg={9} md={9} className='attachment-drop-col'>
                    <div className='product-drop-zone'>
                      <div className='d-flex file-name-icon'>
                        <AttachFileIcon className='attachment-icon' />
                        <p className={selectedFiles?.name ? 'attach-file-name' : 'text-warp'}>{selectedFiles?.name ? selectedFiles?.name : "Attach File"}</p>
                      </div>
                      <DropzoneComponent
                        hint={'Please upload only .png .jpg .jpeg file'}
                        dropImageClass={"img-md"}
                        multiple={false}
                        eventHandlers={eventHandlers}
                        config={dropzoneConfig}
                        djsConfig={djsConfig}
                        acceptedFiles={"image/jpeg,image/jpg,image/png,application/pdf"}
                      />
                    </div></Col>

                  <Col className='d-flex justify-content-end attachment-save-btn-col' sm={3} lg={3} md={3}><Button className={attachmentLoading ? 'btn-success' : 'btn-black'} onClick={saveNotesAttachment}>{attachmentLoading ? "Processing..." : "Save"}</Button></Col>
                </Row>

                {/* TIMELINE WISE ATTACHMENT VIEW */}
                <Timeline>
                  {
                    sortedAttachment?.map((item) => {
                      var fileExtension = item?.type?.split('/')[1];

                      return <TimelineItem>
                        <Box sx={{ flexDirection: 'column', mt: 1 }} className="me-3">
                          <Typography>{moment(item?.createdAt, "Do MMM YYYY h:mm A").format("MMM DD YYYY")}</Typography>
                          <Typography variant='caption'>{moment(item?.createdAt, "Do MMM YYYY h:mm A").format("h:mm A")}</Typography>
                        </Box>
                        <TimelineSeparator >
                          <TimelineDot color='secondary' />
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                          <Box >
                            <div>
                              <p className='ms-1 file_name mb-1'>{item?.notes}</p>
                              {item?.file &&
                                <>
                                  <div className='d-flex mt-2'>
                                    <FileIcon fileType={fileExtension} />
                                    <div>
                                      <p onClick={() => handleDownload(CLOUD_FRONT_URL + item?.file, item?.file)} className='file_name mb-2 cursor-pointer' >{item?.name}</p>
                                      <p
                                        onClick={() => handleDownload(CLOUD_FRONT_URL + item?.file, item?.file)}
                                        style={{
                                          cursor: 'pointer'
                                        }}
                                        className='mb-0'
                                      >Download</p>
                                    </div>
                                  </div>
                                </>
                              }
                            </div>
                          </Box>
                        </TimelineContent>
                      </TimelineItem>
                    })
                  }
                </Timeline>
              </div>

              <div className="enquire">
                <div className="enquire-button">
                  <button className="btn-black w-100"
                    onClick={selectItem}
                  >
                    Select Item
                  </button>
                </div>
              </div>
            </div>
          }

        </Col>
      </Row>
    </div >
  )
}

export default FolderImage