import React, { useState } from "react";
import Logo from "../assets/images/logo.png";
import { Link } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import AddSelection from "../modals/AddSelection";

import { ReactComponent as Share } from "../assets/images/icons/share.svg";
import { ReactComponent as Close } from "../assets/images/icons/close.svg";

// import ProductGallary from "./GallarySlider";

const ProductGalleryDetails = () => {
  const [addModalOpen, setAddModalOpen] = useState(false);

  const handleAddClick = () => {
    setAddModalOpen(true);
  };
  const handleClose = () => {
    setAddModalOpen(false);
  };
  return (
    <>
      <div className="product-details">
        <div className="product-details-body">
          <div className="product-details-body-close">
            <Close />
          </div>
          <div className="product-details-header">
            <div>
              <Link to="/">
                <img
                  src={Logo}
                  alt={"GPEI"}
                  title={"GPEI"}
                  className="product-details-logo logo"
                />
              </Link>
              <h6 className="product-details-tagline">
                Golden Pankh Export Import
              </h6>
            </div>
            <div className="d-md-none">
              <button className="product-images-button-icon">
                <Share />
              </button>
            </div>
          </div>
          <p className="product-details-sku">11271</p>
          <h1 className="product-details-title mb-4">SET OF 3 CANDLE STAND</h1>
          <div className="product-imagesm product-images-wrap d-sm-none">
          {/* <div className="product-imagesm product-images-wrap d-lg-none"> */}
            <div className="product-images-wrap">
              {/* <ProductGallary /> */}
            </div>
          </div>
          <div className="dimensions">
            <div className="dimensions-lable">
              <span>PRODUCT DIMENSIONS</span>
            </div>
            <Tabs defaultActiveKey="cm" className="mb-3">
              <Tab eventKey="in" title="IN">
                <div className="details">
                  <div className="d-flex justify-content-between details-list">
                    <span className="details-list-title">Small</span>
                    <span className="details-list-value">
                      10 L X 10 W X 36 H IN
                    </span>
                  </div>
                  <div className="d-flex justify-content-between details-list">
                    <span className="details-list-title">Medium</span>
                    <span className="details-list-value">
                      10 L X 10 W X 30 H IN
                    </span>
                  </div>
                  <div className="d-flex justify-content-between details-list">
                    <span className="details-list-title">Large</span>
                    <span className="details-list-value">
                      59 L X 35 W X 49 H IN
                    </span>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="cm" title="CM">
                <div className="details">
                  <div className="d-flex justify-content-between details-list">
                    <span className="details-list-title">Small</span>
                    <span className="details-list-value">
                      10 L X 10 W X 36 H CM
                    </span>
                  </div>
                  <div className="d-flex justify-content-between details-list">
                    <span className="details-list-title">Medium</span>
                    <span className="details-list-value">
                      10 L X 10 W X 30 H CM
                    </span>
                  </div>
                  <div className="d-flex justify-content-between details-list">
                    <span className="details-list-title">Large</span>
                    <span className="details-list-value">
                      59 L X 35 W X 49 H CM
                    </span>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>

          <div className="details">
            <div className="details-title">PRODUCT DETAILS</div>
            <div className="d-flex justify-content-between details-list">
              <span className="details-list-title">Material</span>
              <span className="details-list-value">Iron/Wood</span>
            </div>
            <div className="d-flex justify-content-between details-list">
              <span className="details-list-title">Finish</span>
              <span className="details-list-value">Rustic</span>
            </div>
            <div className="d-flex justify-content-between details-list">
              <span className="details-list-title">Carton Dimension</span>
              <span className="details-list-value">59 L X 35 W X 49 H CM</span>
            </div>
            <div className="d-flex justify-content-between details-list">
              <span className="details-list-title">CBM / PC</span>
              <span className="details-list-value">0.101</span>
            </div>
            <div className="d-flex justify-content-between details-list">
              <span className="details-list-title">IP/MP</span>
              <span className="details-list-value">1/8 PCS</span>
            </div>
          </div>
        </div>
        <div className="enquire">
          <div className="enquire-button">
            <button className="btn-black w-100" onClick={handleAddClick}>
              Select Item
            </button>
          </div>
        </div>
      </div>
      <AddSelection show={addModalOpen} onHide={handleClose} />
    </>
  );
};

export default ProductGalleryDetails;
