// ============= LOCAL ============= //
// export const gqlUrl = 'http://localhost:4000/atlas9'


// ============= TESTING OR LOCAL ============= //
// export const CLOUD_FRONT_URL = "https://in.pothigara.com/public/";
// export const gqlUrl = 'https://test.virtuosopastiche.com/test/atlas9'
// export const identityPoolId = "ap-south-1:b80f6796-d478-4d22-a667-8ad2ee1df343"
// export const userPoolId = "ap-south-1_lziB8ocwA"
// export const userPoolWebClientId = "1stdi9lmp53f3vikqv9pakebov"
// export const region = "ap-south-1";
// export const APP_BUCKET = "inpothigara"
// export const APP_BUCKET_REGION = "ap-south-1";
// export const SUPPLIER_BUCKET = "suppliers.pothigara.com"
// export const USER_EMAIL = "admin@polynine.com";
// export const USER_PASSWORD = "Abc@1234";


// ============= PRODUCTION ============= //
export const CLOUD_FRONT_URL = "https://app.poly9.ai/public/";
export const gqlUrl = 'https://apis.polyfoundry.com/prod/atlas9'
export const identityPoolId = "ap-south-1:bf37c625-d53b-44f5-ae74-195ed4402096"
export const userPoolId = "ap-south-1_w9cF2EkpO"
export const userPoolWebClientId = "33gem5k6ih860gmrc8a6deveq2"
export const region = "ap-south-1";
export const APP_BUCKET = "app.poly9.ai"
export const APP_BUCKET_REGION = "ap-south-1"
export const SUPPLIER_BUCKET = "suppliers.polynine.com"
export const USER_EMAIL = "dron@polynine.com";
// export const USER_PASSWORD = "Dron@123";
export const USER_PASSWORD = "Dron@2026";


// ============= TEST && PRODUCTION ============= //
export const COGNITO_REGION = 'ap-south-1'

export const ipApiKey = [
    "277190db9a6b4e0d9d9a40f567857780",
    "d5c63fcf4e3447799bca3dd014ec62aa",
    "3d26c1c4143c4187a12975926d0ca7a4",
    "b0fa227767ab41f08464f31fae05e729",
    "b9ef0845922c46aa89acc28fb8e8a70f",
    "d86e22e16e154320bd4086aa20a6c6c4",
    "83b8e48c2bf949249ff063de8fefb9f7",
    "87dc8cbef20b429b876346e4e2494e8d",
    "0dcad075043d4956aabf3afbabcc8490",
    "b2b3146bba2b4b24b54ecb23a2a65198",
    "23216abf1a264600814a8f4a79237b25"
]