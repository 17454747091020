import { gql } from "@apollo/client";
export const GET_SHORTEN_URL = gql`
query getUrl($shortId: String!) {
    getRedirectURL(shortId: $shortId) {
      redirectURL
      product{
        _id
      }
    }
  }
`