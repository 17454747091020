import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useState } from "react";

const SelectionsForm = ({ submitEnquiry, cartProduct, enquiryLoader }) => {
  const [enquiryFiled, setEnquiryFiled] = useState({
    companyName: null,
    firstName: null,
    lastName: null,
    email: null,
  })
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if (enquiryFiled?.companyName && enquiryFiled?.firstName && enquiryFiled?.lastName && enquiryFiled?.email && cartProduct?.length) {
      event.preventDefault();
      submitEnquiry(enquiryFiled)
    }
  };

  const handleInputChange = (name, value) => {
    setEnquiryFiled({ ...enquiryFiled, [name]: value })
  }
  return (
    <>
      <div className="form">
        <div className="form-title">Your Information</div>
        <div>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicFirstName">
              <Form.Label>Company Name</Form.Label>
              <Form.Control required onChange={(e) => { handleInputChange("companyName", e.target.value) }} value={enquiryFiled?.companyName} type="text" placeholder="Company Name" />
              <Form.Control.Feedback type="invalid">
                Please enter company name
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formBasicFirstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control required onChange={(e) => { handleInputChange("firstName", e.target.value) }} value={enquiryFiled?.firstName} type="text" placeholder="First Name" />
              <Form.Control.Feedback type="invalid">
                Please enter first name
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formBasicLastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control required onChange={(e) => { handleInputChange("lastName", e.target.value) }} value={enquiryFiled?.lastName} type="text" placeholder="Last Name" />
              <Form.Control.Feedback type="invalid">
                Please enter last name
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control required onChange={(e) => { handleInputChange("email", e.target.value) }} value={enquiryFiled?.email} type="email" placeholder="Email" />
              <Form.Control.Feedback type="invalid">
                Please enter email
              </Form.Control.Feedback>
            </Form.Group>
            <div className="d-flex justify-content-center">
              <Button type="submit" className="btn-black w-100">
                {enquiryLoader ? "Loading..." : "Submit Selection"}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default SelectionsForm;
