
export const updateWebpImageSupport = (imageURL) => {
  let url = imageURL;
  if (url) {
    if (window.webpSupport) {
      let { filepath, filename } = extractFilePath(url);
      return `${filepath}${filename}.webp`;
    }
  }
  return url;
};

const extractFilePath = (baseURL) => {
  let url = baseURL;
  let ind1 = url.lastIndexOf('/');
  return {
    filepath: url.substring(0, ind1 + 1), // path/to/dummy/files/
    extension: url.substring(url.lastIndexOf('.') + 1, url.length), // .png
    filename: (url.substring(url.lastIndexOf('/') + 1, url.length)).split('.').slice(0, -1).join('.'), //dummy_filename
    name: url.substring(url.lastIndexOf('/') + 1, url.length), // dummy_filename.png
  }
}

export const validateFileSize = (sizeLimitInMB, uploadedFileSize) => {
  return (uploadedFileSize / 1024 / 1024) <= sizeLimitInMB
};

export const validateFileType = (acceptedFiles, type) => {
  return (acceptedFiles).includes(type.toLowerCase())
};

export const acceptanceImageFilesExtension = () => {
  return ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf']
};

export const customProgressCallback = (progress, data) => {
  if (data.previewElement) {
    let progressElement = data.previewElement.querySelector("[data-dz-uploadprogress]");
    let successElement = data.previewElement.querySelector('.dz-preview .dz-success-mark');
    let progressPercentage = parseInt((progress.loaded * 100) / progress.total) + "";
    try {
      if (Number(progressPercentage) === 100) {
        // progressElement.setAttribute('style', `width: ${progressPercentage}% !important; background: #4BB543 !important;`);
        progressElement.setAttribute('style', `width: ${progressPercentage}% !important;`);
        successElement.setAttribute('style', `opacity: 0.8;`);
      } else {
        progressElement.setAttribute('style', `width: ${progressPercentage}% !important`);
      }
    } catch (e) {
      // console.log(`${data.name} e = `,e)
    }
  }
};

export const onlyFirstLetterCapital = function (str = '') {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const imageResizeLambda = (data) => {
  let url = `${window._env.IMAGE_RESIZE}/resize/?key=` + data.key + '&bucket=' + window._env.APP_BUCKET + '&originpath=' + data.originpath + '/&size=' + data.size + '&destinationpath=' + data.destinationpath + '&onlywebp=' + data.onlywebp;
  return fetch(url).then((data) => {
    console.log('image R success', data)
  }).catch((e) => {
    console.log('image R failed', e.message)
  });
}

export const toDataUrl = (url, callback) => {
  let xhr = new XMLHttpRequest();
  xhr.onload = function () {
    let reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open("GET", url);
  xhr.responseType = "blob";
  xhr.send();
}