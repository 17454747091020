import React from "react";
import { ReactComponent as Close } from "../assets/images/icons/close.svg";
import SelectionsForm from "./SelectionsForm";
import SelectionsProducts from "./SelectionsProducts";
import { Col } from "react-bootstrap";

const CartModal = ({
  cartProduct,
  toggleEnquiryModal,
  toggleCartModal,
  isOpenCartModal,
  removeProductFromCart,
  currencySymbol,
  editNotes,
  submitEnquiry,
  enquiryLoader
}) => {

  return (
    // <Modal show={isOpenCartModal} toggle={toggleCartModal} className="cart-modal">
    <Col lg={5} md={6} sm={12} className={isOpenCartModal ? "selections is-open" : "is-close selections"} style={{ boxShadow:"-20px 0 20px rgba(0, 0, 0, .102)"}}>
      <div className="selections-header" >
        Selections({cartProduct?.length})
        <div span onClick={toggleCartModal} className="cursor-pointer">
          <Close />
        </div>
      </div>
      {
        cartProduct?.length > 0 ?
          <div className="d-flex cart-main">
            <SelectionsProducts cartProduct={cartProduct} removeProductFromCart={removeProductFromCart} editNotes={editNotes} />

            <SelectionsForm submitEnquiry={submitEnquiry} cartProduct={cartProduct} enquiryLoader={enquiryLoader} />
          </div>
          : <h5 className="empty-message">cart is empty</h5>
      }

    </Col>
    // </Modal >
  );
};

export default CartModal;