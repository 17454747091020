import React from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { ReactComponent as Close } from "../assets/images/icons/close.svg";
import thankYouImg from "../assets/images/thankyoumodal.png";
import { CLOUD_FRONT_URL } from "../config";
import { useMutation } from "@apollo/client";
import { SUBMIT_ENQUIRY } from "../pages/query";
import { useState } from "react";

const OrderEnquiry = (props) => {
  const { show, onHide, data } = props;
  const [validated, setValidated] = useState(false);
  const [modal, setModal] = useState(false)
  const [input, setInput] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    notes: ""
  })
  const image = `${CLOUD_FRONT_URL}${data?.image}`
  const changeInput = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  }
  const [mutateFunction] = useMutation(SUBMIT_ENQUIRY);



  const addEnquiry = (e) => {
    // e.preventdefault();
    if (input.firstName && input.lastName && input.email && input.notes) {
      mutateFunction({
        variables: {
          id: data._id,
          enquiryData: {
            companyName: data.companyName,
            firstName: input.firstName,
            lastName: input.lastName,
            email: input.email,
            notes: input.notes
          }
        },
        fetchPolicy: "network-only",
      }).then(data => {
        onHide();
        setModal(true);
        console.log("data", data)
      }).catch((error) => {
        onHide()
        console.log("error", error)
      })

    } else {
      setValidated(true)
    }
  }
  return (
    <>
      <Modal show={modal} centered>
        <Modal.Header className="thanks-modal-header">
          <span className="modal-close" onClick={() => setModal(false)}>
            <Close />
          </span>
        </Modal.Header>
        <Modal.Body className="thanks-modal-body">
          <img src={thankYouImg} alt="" />
          <span>Thank you for inquiry!</span>
          <p>We will reach out to you with requested details shortly at your <span>{input?.email}</span></p>
        </Modal.Body>
      </Modal>

      <Modal onHide={onHide} show={show} className="enquire-modal">
        <Modal.Header>
          <span
            className="modal-close"
            onClick={onHide}
            style={{
              padding: "10px 0px 0px"
            }}
          >
            <Close />
          </span>
        </Modal.Header>
        <Modal.Body className="enquire-mdl-body">
          <div className="enquire-modal-product">
            <img src={image} alt="p1" />
            <div className="enquire-modal-product-details">
              <div className="enquire-modal-product-details-title">
                {data?.name}
              </div>
              {!data?.isSetIOf3tem && data?.packageDimension ? <div className="enquire-modal-product-details-subtitle">
                <span>Dimensions:</span> {`${data?.packageDimension?.width} W X ${data?.packageDimension?.height} H ${data?.packageDimension?.unit.toUpperCase()}`}
              </div> : null}
              {!data?.isSetIOf3tem ?
                <div className="enquire-modal-product-details-subtitle">
                  <span>Material:</span> {data?.materials}
                </div> : null

              }
            </div>
          </div>
          <div className="form">
            <div className="form-title">YOUR ORDER ENQUIRY DETAILS</div>
            <div>
              <Form noValidate validated={validated}>
                <Form.Group className="mb-2" controlId="formBasicFirstName">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control required name="firstName" type="text" placeholder="Enter first name" onChange={(e) => changeInput(e)} />
                </Form.Group>
                <Form.Group controlId="formBasicLastName">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control required name="lastName" type="text" placeholder="Enter last name" onChange={(e) => changeInput(e)} />
                </Form.Group>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control required name="email" type="email" placeholder="Enter email" onChange={(e) => changeInput(e)} />
                </Form.Group>
                <Form.Label>Items Request Notes</Form.Label>
                <FloatingLabel controlId="floatingTextarea">
                  <Form.Control
                    name="notes"
                    placeholder="Enter notes"
                    as="textarea"
                    required
                    rows={4}
                    onChange={(e) => changeInput(e)}
                  />
                </FloatingLabel>
                <div className="d-flex justify-content-center">
                </div>
              </Form>
              <Button variant="primary" type="submit" className="btn-black" onClick={(e) => addEnquiry(e)}>
                Submit
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OrderEnquiry;
