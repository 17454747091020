import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PageNotFound from "../pages/PageNotFound";
import ProductDetails from "../pages/ProductDetails";
import FolderDetails from "../pages/FolderDetails";
import ProductsGalleryDetails from "../pages/ProductsGalleryDetails";
// import ProductsList from "../pages/ProductsLists";
import ShareProductComponent from "../pages/shareProductQuery/ShareProductComponent";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/main.scss";
import Folder2DProductViewModal from "../pages/folder2DProductViewModal";
import Folder3DProductViewModal from "../pages/folder3DProductViewModal";
import { useState } from "react";
import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { GET_COMPANY_DETAIL_BY_COMPANY_ID } from "../pages/shareFolderQuery/query";
import { ipApiKey } from "../config";

export default function RoutesLink() {
  const [country, setCountry] = useState("");
  const [companyDetails, setCompanyDetails] = useState()

  const { data: companyDetail } = useQuery(GET_COMPANY_DETAIL_BY_COMPANY_ID, {
    variables: { companyId: window.location.pathname.split('/')[3] }, // GP companyId = 5dfdd4534deb7fb955296d21
    // variables: { companyId: "5dfdd4534deb7fb955296d21" }, // GP companyId = 5dfdd4534deb7fb955296d21
    fetchPolicy: "network-only"
  })

  useEffect(() => {
    if (companyDetail?.getCompanyDetailByCompanyId) {
      setCompanyDetails(companyDetail?.getCompanyDetailByCompanyId);
    }
  }, [companyDetail?.getCompanyDetailByCompanyId])
  useEffect(() => {
    async function getapi(url) {
      const response = await fetch(url);
      var data = await response.json();

      if (data) {
        setCountry(data);
      }
    }
    const randomKey = ipApiKey[Math.floor(Math.random() * ipApiKey.length)];
    const api_url = `https://api.ipgeolocation.io/ipgeo?apiKey=${randomKey}`;

    getapi(api_url);
  }, []);
  return (
    <>
      <BrowserRouter>
        <Routes>
          {!companyDetails?.ipWhiteList?.includes(country?.ip) && companyDetails?.accessRestrictedCountry?.includes(country?.country_name) ? (
            <Route path="*" element={<PageNotFound />} />
          ) : (
            <>
              {/* <Route path="/" element={<ProductsList />} /> */}
              <Route path="/" element={<PageNotFound />} />
              <Route path="/s/:shortId" element={<ShareProductComponent />} />
              <Route
                path="/share/images/:companyId/:_id"
                element={<ProductDetails />}
              />
              <Route
                path="/share/folder/:companyId/:_id"
                element={<FolderDetails />}
              />
              <Route
                path="/share/folder/:companyId/:_id?_iId"
                element={<Folder2DProductViewModal />}
              />
              <Route
                path="/share/folder/:companyId/:_id?_pid"
                element={<Folder3DProductViewModal />}
              />
              <Route path="/select-item" element={<ProductsGalleryDetails />} />
              <Route path="*" element={<PageNotFound />} />
            </>
          )}
        </Routes>
      </BrowserRouter>
    </>
  );
}
